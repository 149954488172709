import React from 'react';
import { motion } from 'framer-motion';
import { CallToAction, Flex, Heading, Text, useLocalStorage } from '@nex/labs';

import classNames from 'classnames';

import CheckIcon from '@nex/icons/svg/misc/check.svg';

import { type TUser } from '@nex/types';

import styles from '../console-home.module.scss';

export const SetupChecklist = ({
  onboardingChecklist,
}: {
  onboardingChecklist?: TUser['onboardingChecklist'];
}) => {
  const [isChecklistOpen, setIsChecklistOpen] = useLocalStorage<boolean>(
    '[nex]:isChecklistOpen',
    true
  );

  const updateStatus = (key: string) => {
    // Update the status of the checklist item
    if (!onboardingChecklist) return false;

    switch (key) {
      case 'hasArtboard':
        return onboardingChecklist.hasCreatedArtboard;
      case 'hasEnhancedImage':
        return onboardingChecklist.hasEnhancedImage;
      case 'hasInvitedTeamMembers':
        return onboardingChecklist.hasInvitedMembers;
      default:
        return false;
    }
  };

  const checklist = [
    {
      title: 'Create an Artboard',
      status: updateStatus('hasArtboard'),
      excerpt: 'Create your first artboard',
      infographic: '/images/dashboard/1.png',
      description:
        'Go beyond text-to-image with our powerful block based editor.',
      action: '/studio/new',
      actionText: 'Create Artboard',
    },
    {
      title: 'Enhance Image',
      excerpt: 'Upscale your images',
      status: updateStatus('hasEnhancedImage'),
      infographic: '/images/dashboard/2.png',
      description: 'Creatively upscale images to 2x resolution',
      action: '/enhance',
      actionText: 'Enhance Image',
    },
    {
      title: 'Invite Team Members',
      excerpt: 'Do more together',
      status: updateStatus('hasInvitedTeamMembers'),
      infographic: '/images/dashboard/3.png',
      description: 'Enable the full functionality of NEX with workspaces',
      action: '/settings/workspace?page=members',
      actionText: 'Invite Team Members',
    },
  ];

  const [active, setActive] = React.useState(
    checklist.findIndex((item) => !item.status)
  );

  const currentChecklist = checklist[active];

  if (!isChecklistOpen || checklist.filter((item) => item.status).length >= 2)
    return null;

  return (
    <Flex.Column gap={40}>
      <div className={styles.SetupChecklist}>
        <Flex.Column gap={16} className={styles.Checklist}>
          <Flex.Row
            gap={12}
            className="mb-[8px]"
            alignContent="center"
            justifyContent="space-between"
          >
            <Flex.Column className="mb-[8px]" gap={2}>
              <Heading.h6 weight={700}>Onboarding</Heading.h6>
              <Text className="opacity-80">
                Start with these three simple tasks
              </Text>
            </Flex.Column>
            <button onClick={() => setIsChecklistOpen(false)}>
              <Text weight={600} color="var(--text-gray)">
                Close
              </Text>
            </button>
          </Flex.Row>

          {checklist.map((item, index) => (
            <button
              type="button"
              key={item.title}
              className={classNames([
                styles.ChecklistItem,
                active === index && styles.active,
                item.status && styles.done,
              ])}
              disabled={item.status}
              onClick={() => setActive(index)}
              aria-label={item.title}
            >
              <Flex alignItems="flex-start" gap={12}>
                <div className={styles.ChecklistItemStatus}>
                  {item.status ? (
                    <CheckIcon width="16" height="16" />
                  ) : (
                    <span className={styles.ChecklistItemStatusIndex}>
                      {index + 1}
                    </span>
                  )}
                </div>

                <Flex.Column
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Text weight={700}>{item.title}</Text>
                  <Text className="opacity-80">{item.excerpt}</Text>
                </Flex.Column>
              </Flex>
            </button>
          ))}
        </Flex.Column>
        <motion.div
          className={styles.ChecklistItemDetails}
          key={currentChecklist?.title}
        >
          {currentChecklist.infographic && (
            <motion.img
              src={currentChecklist.infographic}
              alt="placeholder"
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 15 }}
            />
          )}
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ delay: 0.1 }}
          >
            <Flex.Column gap={14} className="max-w-[400px]">
              <Heading.h6 weight={700} className="-mb-2">
                {currentChecklist.title}
              </Heading.h6>
              <Text weight={600}>{currentChecklist.description}</Text>
              <CallToAction.a
                className={styles.ChecklistItemAction}
                href={currentChecklist.action}
              >
                {currentChecklist.actionText}
              </CallToAction.a>
            </Flex.Column>
          </motion.div>
        </motion.div>
      </div>
    </Flex.Column>
  );
};
