import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';

import {
  CallToAction,
  Field,
  Flex,
  Heading,
  Modal,
  Text,
  pluralize,
  useDebounce,
  useToast,
} from '@nex/labs';

import { FetchContainer } from '@/components/misc/fetch-container';
import analytics from '@lib/analytics';

import Sparkles from '@nex/icons/svg/misc/sparkles.svg';
import { useUserStore } from '@/state/useStore';

import { usePostHog } from 'posthog-js/react';

import styles from './modals.module.scss';
import Router from 'next/router';
import { Folders } from '@/features/console';
import {
  useGetUserFolderQuery,
  useCreateFolderMutation,
  useUpdateFolderMutation,
} from '@/state/query/block';

export const CreateFolderModal = ({
  show,
  onClose,
  type,
  data,
}: {
  show: boolean;
  type?: any;
  data?: any;
  onClose: () => void;
}) => {
  const { createToast } = useToast();

  const [createNew, setCreateNew] = React.useState(
    typeof type !== 'undefined' ? !!type : false
  );
  const posthog = usePostHog();
  const { profile } = useUserStore();
  const [formDetails, setFormDetails] = React.useState<any>({
    name: 'Untitled Folder',
    description: '',
  });

  const {
    data: _data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetUserFolderQuery(
    {
      folderId: data?.id || (Router.query.folderId as string),
    },
    {
      enabled: show && !data,
    }
  );

  const handleClose = useCallback(() => {
    return onClose();
  }, [onClose]);

  const { mutate: createFolder, isLoading: isFolderLoading } =
    useCreateFolderMutation({
      onSuccess: (_data: any) => {
        if (type) {
          handleClose();

          analytics.track('Create new folder', {
            eventAction: `Create Folder - ${_data?.folder?.name}`,
            eventLabel: `A new folder was created (${_data?.folder?.name})`,
            eventValue: `Folder was created ${_data?.folder}`,
          });

          posthog.capture('folder_created_success');
        } else {
          analytics.track('Create and add to folder', {
            eventAction: `Create and Add - ${_data?.folder?.name}`,
            eventLabel: `A new folder was created (${_data?.folder?.name})`,
            eventValue: `${setGenerations(data)} was Added`,
          });

          posthog.capture('folder_generation_created_sucess');
          posthog.capture('folder_added_generation');
        }

        createToast({
          message: 'Folder created successfully',
          variant: 'secondary',
        });

        setFormDetails({
          name: '',
          description: '',
        });
        setCreateNew(false);
      },
      onError: (err: any) => {
        posthog.capture('folder_created_error');
        createToast({
          message: err?.message ?? 'Something went wrong, please try again.',
          variant: 'error',
        });
      },
    });

  const { mutate: updateFolder, isLoading: isUpdateFolderLoading } =
    useUpdateFolderMutation({
      onSuccess: (_data) => {
        if (type) {
          createToast({
            message: 'Folder updated successfully',
            variant: 'secondary',
          });
        } else {
          posthog.capture('folder_added_generation');
          analytics.track('Save to Existing folder', {
            eventAction: `Update and Add - ${_data?.folder?.name}`,
            eventLabel: `A new Image was added to folder (${_data?.folder?.name})`,
            eventValue: `${setGenerations(data)} was Added`,
          });

          createToast({
            message: 'Generation added to folder successfully',
            variant: 'secondary',
          });
        }
      },
      onError: (err: any, data: any) => {
        posthog.capture('folder_added_generation_error');

        createToast({
          message: err?.message ?? 'Something went wrong',
          variant: 'error',
        });
      },
    });

  const addToFolder = (folder: any) => {
    const timeout = setTimeout(() => {
      updateFolder({
        id: folder?.id,
        type: 'add',
        images: setGenerations(data),
        folderId: data?.id || 'root',
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  };

  useEffect(() => {
    if (type === 'edit') {
      setFormDetails({
        name: data?.name,
        description: data?.description,
      });
    }
  }, [type, data]);

  const setGenerations = (data: any) => {
    return [
      data?.images?.length > 0 ? data?.images?.map((d: any) => d.id) : data?.id,
    ]
      .flat()
      .filter(Boolean);
  };

  return (
    <Modal in={show} onClose={handleClose} size="md" title="Folder">
      {!createNew && !type ? (
        <>
          <Heading.h5 weight={700} className="mb-5">
            <Sparkles width={18} className="inline" /> Add file to a folder
          </Heading.h5>

          <Folders
            bare
            view="list"
            folderBind={(data: any) => (
              <Flex
                className="mt-10"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gap={12}
              >
                <CallToAction.button
                  size="sm"
                  variant="secondary"
                  onClick={() => addToFolder(data)}
                >
                  Add to Folder
                </CallToAction.button>
                <CallToAction.button
                  size="sm"
                  isLoading={isUpdateFolderLoading}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Close
                </CallToAction.button>
              </Flex>
            )}
          />
        </>
      ) : (
        <>
          <Heading.h6 weight={700} className="mb-5">
            {type === 'edit' ? 'Edit' : 'Create a new'} Folder
          </Heading.h6>

          <Field.Form
            onSubmit={async (e) => {
              e.preventDefault();

              if (type === 'edit') {
                await updateFolder({
                  id: data?.id,
                  name: formDetails.name,
                  description: formDetails.description,
                  folderId: data?.id,
                });

                return;
              }

              await createFolder({
                name: formDetails.name,
                description: formDetails.description,
                images: setGenerations(data),
              });
            }}
          >
            <Field.Input
              name="name"
              placeholder="Enter a name for your folder"
              required
              value={formDetails.name}
              onChange={(e) => {
                setFormDetails((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />

            <Flex alignItems="center" flexWrap="wrap" gap={12}>
              <CallToAction.button
                size="sm"
                isLoading={isFolderLoading || isUpdateFolderLoading}
                disabled={
                  !formDetails.name ||
                  (type === 'edit' &&
                    formDetails.name === data?.name &&
                    formDetails.description === data?.description)
                }
              >
                {type === 'edit'
                  ? 'Update'
                  : `${data?.id ? 'Create and Add' : 'Create'}`}
              </CallToAction.button>
              <CallToAction.button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  if (type) {
                    handleClose();
                  } else {
                    setCreateNew(false);
                  }
                }}
                variant="secondary"
              >
                Cancel
              </CallToAction.button>
            </Flex>
          </Field.Form>
        </>
      )}
    </Modal>
  );
};
