import React, { useEffect } from 'react';
import classNames from 'classnames';
import Router from 'next/router';
import {
  CallToAction,
  Flex,
  getDataIcons,
  Heading,
  Popover,
  useDebounce,
} from '@nex/labs';
import { useOrganization } from '@clerk/nextjs';

import { FetchContainer } from '@/components/misc/fetch-container';

import FolderIcon from '@nex/icons/svg/misc/folder.svg';
import { ViewFileModal } from './folders/modals/view-file';
import { useSearchParams } from 'next/navigation';

import { useGetUserFilesQuery } from '@/state/query/block';
import AssetCardContainer, { AssetCard } from '../components/asset-card';

import { useFileUpload } from '@/components/upload/useUpload';
import { type ImageData } from '@nex/types/lib/assets';

import styles from './folders/folders.module.scss';
import { useUserStore } from '@/state/useStore';

export const AssetsView = ({
  query,
  bare,
  id,
  isSearch,
  limit,
  view = 'grid',
  onContextSelect,
}: {
  query?: string;
  bare?: boolean;
  id?: string;
  limit?: number;
  view?: 'grid' | 'list';
  isSearch?: boolean | string;
  onContextSelect?: (context: any) => void;
}) => {
  const [show, setShow] = React.useState(false);
  const { onFileUpload, launchModal } = useFileUpload();
  const [currentData, setCurrentData] = React.useState<ImageData | null>(null);
  const { organization } = useOrganization();
  const searchValue = useDebounce(query, 500);
  const searchParams = useSearchParams();
  const file = searchParams?.get('file');

  const { profile } = useUserStore();

  const filter = {
    mine: `creator:${profile.id}`,
    external: `creator:!${profile.id}`,
  };

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useGetUserFilesQuery(
      {
        search: searchValue as string,
        limit: limit || 15,
        folderId: id || (Router.query.folderId as string),
        sort: Router.query.sort as string,
        filter: filter[Router.query.tab as keyof typeof filter],
      },
      {
        enabled: !!organization,
      }
    );

  useEffect(() => {
    if (file) {
      setShow(true);
    }
  }, [data, file, currentData]);

  return (
    <Flex.Column gap={18}>
      {!isSearch && (
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          gap={12}
          alignItems="center"
        >
          <span>
            <Flex gap={4} alignItems="center">
              <Heading.h6 weight={700}>Files</Heading.h6>
            </Flex>
          </span>

          <Popover size="sm">
            <Popover.Trigger>
              <CallToAction
                size="xs"
                className="h-fit"
                variant="secondary"
                leadingIcon={<img src={getDataIcons('add', '#000')} />}
              >
                Upload
              </CallToAction>
            </Popover.Trigger>
            <Popover.Content isDropdown>
              <CallToAction.input
                size="xs"
                className="h-fit p-0"
                variant="clear"
                leadingIcon={<img src={getDataIcons('add', '#000')} />}
                defaultBehavior={false}
                onFileUpload={onFileUpload}
                beforeFileValidation={launchModal}
                multiple
                dragDrop
                acceptedFileTypes={['image/*', 'video/*']}
              >
                New File Upload
              </CallToAction.input>
              <CallToAction.input
                size="xs"
                className="h-fit p-0"
                variant="clear"
                leadingIcon={<FolderIcon width={18} className="is-stroke" />}
                defaultBehavior={false}
                onFileUpload={onFileUpload}
                multiple
                dragDrop
                beforeFileValidation={launchModal}
                acceptedFileTypes={['image/*', 'video/*']}
                isFolder
              >
                New Folder Upload
              </CallToAction.input>
            </Popover.Content>
          </Popover>
        </Flex>
      )}
      <FetchContainer
        isLoading={isLoading || isFetching}
        shouldBeEmpty={!data?.files?.length}
        closeChildOnLoading={isLoading}
        fetchNextPage={fetchNextPage}
        hasMore={hasNextPage}
        useButton={view === 'list'}
        emptyMessage="You don't have any assets yet."
      >
        <div className={styles.Results}>
          <div className={classNames([styles.Mansory, styles[view]])}>
            <AssetCardContainer
              data={data?.files}
              view={view}
              onContextSelect={onContextSelect}
              setCurrentData={setCurrentData}
              setShow={setShow}
            />
          </div>
        </div>
      </FetchContainer>
      {show && (
        <ViewFileModal
          show={show}
          closeModal={() => setShow(false)}
          data={currentData as ImageData}
        />
      )}
    </Flex.Column>
  );
};
