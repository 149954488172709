import { createSmartApi } from '@lib/smart-query';
import { type TUser } from '@nex/types';

export const userApiCreator = createSmartApi({
  key: 'user',
  endpoints: (builder) => ({
    updateProfile: builder.mutation<
      {
        data: any;
      },
      {
        profile: TUser;
      }
    >({
      key: 'updateProfile',
      invalidatesQueries: ['user'],
      mutationFn: ({ data }) => ({
        url: `/profile`,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: data,
      }),
    }),

    getWorkspace: builder.query({
      key: 'workspace',
      queryFn: ({ workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}`,
        method: 'GET',
      }),
    }),

    resendEmail: builder.mutation({
      mutationFn: () => ({
        url: `/email/verify/resend`,
        method: 'PUT',
      }),
    }),

    verifyEmail: builder.mutation({
      invalidatesQueries: ['user'],
      mutationFn: ({ token }) => ({
        url: `/email/verify`,
        method: 'PUT',
        body: {
          token,
        },
      }),
    }),

    createCheckoutSession: builder.mutation<
      {
        nextPlanId: string;
      },
      {
        checkout: any;
      }
    >({
      mutationFn: ({ nextPlanId }) => ({
        url: `/subscriptions/checkout`,
        method: 'POST',
        body: {
          returnUrl: `${window.location.origin}/console/settings/plans?success=true`,

          nextPlanId,
        },
      }),
    }),

    manageBilling: builder.mutation<
      {
        returnUrl: string;
      },
      {
        billing: any;
      }
    >({
      mutationFn: ({ returnUrl }) => ({
        url: `/subscriptions/manage`,
        method: 'POST',
        body: {
          returnUrl,
        },
      }),
    }),

    getReferral: builder.query({
      key: 'referFriend',
      queryFn: () => ({
        url: `/users/referrals`,
        method: 'GET',
      }),
    }),
    referFriend: builder.mutation({
      invalidatesQueries: ['referFriend'],
      mutationFn: ({ email }) => ({
        url: `/users/refer`,
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    getSubscription: builder.query({
      queryFn: () => ({
        url: `/subscriptions/plans`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetSubscriptionQuery,
  useUpdateProfileMutation,
  useVerifyEmailMutation,
  useManageBillingMutation,
  useResendEmailMutation,
  useGetReferralQuery,
  useReferFriendMutation,
  useGetWorkspaceQuery,
  useCreateCheckoutSessionMutation,
} = userApiCreator;
