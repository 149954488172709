import React from 'react';
import AnimatorIcon from '@nex/icons/svg/console/products/animator.svg';
import EnhanceIcon from '@nex/icons/svg/console/products/enhancer.svg';
import CanvasIcon from '@nex/icons/svg/console/products/canvas.svg';
import StudioIcon from '@nex/icons/svg/console/products/studio.svg';
import AssetsIcon from '@nex/icons/svg/dashboard/image.svg';
import MarkoIcon from '@nex/icons/svg/console/products/marko.svg';
import { NativeElementProps } from '@nex/labs';

const icons = {
  animator: AnimatorIcon,
  enhancer: EnhanceIcon,
  canvas: CanvasIcon,
  studio: StudioIcon,
  assets: AssetsIcon,
  marko: MarkoIcon,
};

export const AppIcon = ({
  type,
  ...rest
}: {
  type: keyof typeof icons & string;
} & NativeElementProps<'img'>) => {
  const Comp = icons[type as keyof typeof icons];
  return <Comp {...rest} />;
};
