import React from 'react';
import {
  CallToAction,
  Flex,
  Heading,
  Text,
  useDebounce,
  getDataIcons,
} from '@nex/labs';
import Link from 'next/link';

import { useUserStore } from '@/state/useStore';
import { FetchContainer } from '@/components/misc/fetch-container';

import Bookmark from '@nex/icons/svg/misc/structure.svg';

import { CreateFolderModal } from '@/components/layout';

import styles from '../library.module.scss';
import { useGetAnimationsQuery } from '@/state/query/animator';
import { AnimationCard } from '../components/animation-card';

interface AnimationsProps {
  query?: string;
  bare?: boolean;
  isSearch?: boolean | string;
  limit?: number;
}

export const Animations = ({
  query,
  bare,
  isSearch,
  limit,
}: AnimationsProps) => {
  const { profile } = useUserStore();

  const [createNew, setCreateNew] = React.useState(false);
  const searchValue = useDebounce(query, 500);

  const { data, isLoading, fetchNextPage, hasNextPage } = useGetAnimationsQuery(
    {
      limit: limit || 15,
      search: searchValue as string,
      workspaceId: profile?.organizationId,
    },
    {
      enabled: !!profile?.organizationId,
    }
  );

  return (
    <div className="mt-1">
      <div className={styles.ConsoleWrapper}>
        <div className={styles.ConsoleRecentWorks}>
          <FetchContainer
            isLoading={isLoading}
            hasMore={hasNextPage}
            fetchNextPage={
              typeof limit === 'undefined' ? fetchNextPage : undefined
            }
            shouldBeEmpty={!data?.animations?.length}
            emptyMessage={
              isSearch
                ? 'No animations found matching your search'
                : 'You have no animations yet. Create your first animation!'
            }
          >
            {!isSearch && !bare && (
              <>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  gap={12}
                  alignItems="center"
                >
                  <span>
                    <Flex gap={4}>
                      <Bookmark width={14} className="opacity-80" />{' '}
                      <Heading.h5 weight={700}>Animations</Heading.h5>
                    </Flex>
                  </span>

                  <CallToAction.a
                    size="xs"
                    className="h-fit"
                    variant="secondary"
                    href="/animator"
                    leadingIcon={<img src={getDataIcons('add', '#000')} />}
                  >
                    New Animation
                  </CallToAction.a>
                </Flex>
              </>
            )}

            {data?.animations?.length ? (
              <div className={styles.ArtboardGrid}>
                {data?.animations
                  ?.slice(0, limit || data?.animations?.length)
                  ?.map((animation) => (
                    <AnimationCard key={animation.id} animation={animation} />
                  ))}

                <Link
                  href="/animator"
                  className={styles['ConsoleArtboardsArea__card--add']}
                >
                  <span>
                    <img src={getDataIcons('add')} />
                  </span>
                  <Text weight={600}>New Animations</Text>
                </Link>
              </div>
            ) : null}
          </FetchContainer>

          <CreateFolderModal
            onClose={() => setCreateNew(false)}
            show={createNew}
            type="create"
          />
        </div>
      </div>
    </div>
  );
};
