import React, { useMemo } from 'react';

import {
  Avatar,
  CallToAction,
  extractImage,
  Flex,
  humanReadableSize,
  Img,
  Modal,
  Spinner,
  Text,
  useFeedback,
} from '@nex/labs';

import {
  useDeleteAssetMutation,
  useGetUserFileQuery,
} from '@/state/query/block';
import { useOrganization } from '@clerk/nextjs';
import { formatDistanceToNow } from 'date-fns';
import { type ImageDataArray, ImageData } from '@nex/types/lib/assets';
import { useUserStore } from '@/state/useStore';
import { AppIcon } from '@/components/misc/app-icon';
import styles from './modals.module.scss';
import { Video } from '@/features/console/animator/components/video';
import ReadMore from '@/components/misc/read-more';
import { AssetActions } from '../../../components/asset-card';
import Router from 'next/router';
import queryString from 'query-string';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { RenderIf } from '@/components/misc/render-if';

export const ViewFileModal = ({
  show,
  closeModal,
  data,
}: {
  show: boolean;
  closeModal: () => void;
  data: ImageData;
}) => {
  const router = useRouter();

  const { data: _data, isFetching } = useGetUserFileQuery(
    {
      id: Router.query.file as string,
    },
    {
      enabled: !!Router.query.file && !data,
    }
  );

  const generation = useMemo(() => {
    if (data) return data;
    return _data?.file;
  }, [data, _data]);

  const imageKey = generation?.workspace?.slug + '/' + generation?.key;
  const generationActions = {
    Marko: [
      {
        action: `/marko?imageKey=${encodeURIComponent(imageKey!)}`,
        text: 'Ask Marko',
      },
    ],

    Canvas: [
      {
        action: `/canvas?imageKey=${encodeURIComponent(imageKey!)}`,
        text: 'Edit',
      },
    ],

    Enhancer: [
      {
        action: `/enhance?img=${encodeURIComponent(extractImage(generation))}`,
        text: 'Enhance',
      },
    ],
    Animator: [
      {
        action: `/animator?img=${encodeURIComponent(
          extractImage(generation)
        )}&imageKey=${encodeURIComponent(imageKey!)}`,
        text: 'Make Video',
      },
    ],
  };

  const fileType = generation?.type.split('/')[0];
  const Components = {
    image: () => (
      <Img
        src={extractImage(generation)}
        alt={generation?.caption ?? 'Assets'}
        className="max-w-[800px] max-h-[90vh] m-auto object-contain rounded-md"
      />
    ),
    video: () => (
      <Video
        animation={
          {
            ...generation,
            animationKey: generation.key,
          } as any
        }
        slug={generation.workspace?.slug}
      />
    ),
  } as Record<string, any>;

  const Component = Components[fileType || 'image'];

  const fileInfo = useMemo(() => {
    if (!generation) return [];
    return Object.keys(generation as Record<string, any>).reduce(
      (acc, key) => {
        const allowedKeys = ['type', 'size', 'name'];
        if (!allowedKeys.includes(key)) return acc;

        switch (key) {
          case 'type':
          case 'name':
            acc.push({
              key: key,
              value: generation[key],
            });
            break;

          case 'size':
            acc.push({
              key: key,
              value: humanReadableSize(generation[key]),
            });
            break;

          default:
        }

        return acc;
      },
      [] as {
        key: string;
        value: string;
      }[]
    );
  }, [generation]);

  return (
    <Modal
      title=""
      size={1300}
      in={show}
      onClose={() => {
        closeModal();
        if (Router.query.file) {
          router.replace(
            queryString.stringifyUrl(
              {
                url: Router.asPath,
                query: { file: undefined },
              },
              {
                skipNull: true,
                skipEmptyString: true,
              }
            ),
            undefined
          );
        }
      }}
    >
      <Flex.Row gap={12} justifyContent="space-between">
        <Flex.Column
          gap={4}
          className={styles.GenerationImageWrapper}
          justifyContent="center"
        >
          {isFetching && <Spinner text="Loading…" fullPage />}

          <div className={styles.GenerationImage}>
            <Component />
            <div
              className={classNames([
                styles.GenerationImageActions,
                styles[fileType],
              ])}
            >
              <Flex gap={8} alignItems="center">
                <CallToAction.button
                  size="xs"
                  variant="clear"
                  download={extractImage(generation)}
                >
                  Download
                </CallToAction.button>
                <AssetActions asset={generation} />
              </Flex>
            </div>
          </div>
        </Flex.Column>
        <Flex.Column className="basis-[400px] justify-between gap-8 ">
          <Flex.Column gap={4}>
            <RenderIf condition={!!generation?.creator}>
              <Flex gap={8} alignItems="center" className="mb-1">
                <Avatar
                  src={generation?.creator?.photo}
                  alt={generation?.creator?.name}
                  size={30}
                />

                <Text weight={700}>{generation?.creator?.name}</Text>
              </Flex>
            </RenderIf>

            <RenderIf condition={!!generation?.updatedAt}>
              <Text
                className="opacity-70"
                fontSize="var(--font-caption)"
                weight={500}
              >
                Uploaded{' '}
                {generation?.updatedAt &&
                  formatDistanceToNow(new Date(generation?.updatedAt))}{' '}
                ago
              </Text>
            </RenderIf>

            <Flex.Row gap={8} className="mt-4">
              {fileInfo.map(({ key, value }) => (
                <Flex.Column
                  key={key}
                  gap={8}
                  className="flex-1 break-all basis-[32%]"
                >
                  <Text weight={600} casing="capitalize">
                    {key}
                  </Text>
                  <Text weight={500}>
                    <ReadMore text={value} limit={20} />
                  </Text>
                </Flex.Column>
              ))}
              <Flex.Column gap={8} className="flex-1 basis-[100%]">
                <Text weight={600}>Caption</Text>
                <Text weight={500}>
                  <ReadMore
                    text={generation?.caption || 'No caption'}
                    limit={200}
                  />
                </Text>
              </Flex.Column>
            </Flex.Row>
          </Flex.Column>

          {fileType === 'image' && (
            <Flex.Row gap={8} className="mt-auto">
              <Flex.Column className="mt-auto relative" gap={12} fullWidth>
                <Text weight={800}>Use this file In</Text>
                <Flex.Column gap={8}>
                  {Object.entries(generationActions)
                    .filter(([key]) => {
                      return !key
                        .toLowerCase()
                        .startsWith((Router.asPath as string)?.split('/')[1]);
                    })
                    .map(([key, actions]) => (
                      <Flex key={key} gap={12} fullWidth>
                        <Flex alignItems="center" gap={8}>
                          <AppIcon
                            type={key.toLowerCase() as any}
                            color="#000"
                            width={20}
                            height="auto"
                          />
                          <Text weight={600} casing="capitalize">
                            {key}
                          </Text>
                        </Flex>
                        <Flex
                          gap={2}
                          flexWrap="wrap"
                          alignItems="center"
                          fullWidth
                        >
                          {actions.map((action: any, index: number) => {
                            const CallToActionComp =
                              typeof action.action === 'string'
                                ? CallToAction.a
                                : CallToAction.button;

                            if (action.component) return action.component;
                            return (
                              <CallToActionComp
                                key={index}
                                size="xs"
                                className="w-full text-[13px] ml-auto max-w-[200px]"
                                {...(typeof action.action === 'string'
                                  ? {
                                      href: action.action,
                                    }
                                  : {
                                      onClick: action.action,
                                    })}
                                variant="secondary"
                              >
                                {action.text}
                              </CallToActionComp>
                            );
                          })}
                        </Flex>
                      </Flex>
                    ))}
                </Flex.Column>
              </Flex.Column>
            </Flex.Row>
          )}
        </Flex.Column>
      </Flex.Row>
    </Modal>
  );
};
