import { useGetModelsQuery } from '@/state/query/block';
import { useArtboardStore } from '@/state/useStore';
import {
  CallToAction,
  Flex,
  getDataIcons,
  Tabs,
  Text,
  Tooltip,
} from '@nex/labs';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import styles from './views.module.scss';
import { ArtboardPresetsBlock } from './presets';
import { IconMap } from '@/components/misc/controls-icon';

const MODEL_IMAGES = {
  'Ikon-1': '/images/dashboard/ikon.png',
  'Ikon 2': '/images/dashboard/ikon-2.png',
  'FLUX.1': '/images/dashboard/flux.png',
} as Record<string, string>;

export const ArtboardModel = () => {
  const { models, defaultConfig, setConfig } = useArtboardStore();
  const [selectedModel, setSelectedModel] = React.useState<{
    name: string;
    slug: string;
    blocks: string[];
    isDefault: boolean;
  } | null>(defaultConfig?.model || null);

  useEffect(() => {
    if (!defaultConfig?.model?.id && models.length) {
      const defaultModel = models.find((model) => model.isDefault) || models[0];
      setSelectedModel(defaultModel);
    }
  }, [models]);

  const handleModelChange = (modelSlug: string) => {
    const newModel = models?.find((model) => model.slug === modelSlug);

    if (newModel) {
      setSelectedModel(newModel);
      setConfig({
        model: newModel,
      });
    }
  };

  const availableControls = ['text', ...(selectedModel?.blocks || [])];

  if (!models?.length) return null;

  return (
    <Flex.Column gap={18}>
      <Flex.Column className="mb-1">
        <Text weight={600} className="-mb-2">
          Models
          <Tooltip content="Upload assets and add them as controls in your generations">
            <img
              src={getDataIcons('help')}
              className="inline w-[12px] -mt-[2px] ml-2"
            />
          </Tooltip>
        </Text>
      </Flex.Column>

      <Tabs
        defaultTab="/ikon-1"
        selectedKey={selectedModel?.slug}
        size="sm"
        onChangeTab={handleModelChange}
        fullWidth
        scrollable
      >
        {models.map((model) => (
          <Tabs.Tab key={model.slug} dataKey={model.slug} title={model.name} />
        ))}
      </Tabs>

      {selectedModel && (
        <motion.div
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={styles.ModelInfo}
          key={selectedModel.name}
        >
          <img
            src={MODEL_IMAGES[selectedModel.name] || MODEL_IMAGES['Ikon-1']}
            alt={selectedModel.name}
          />
          <Flex.Column gap={2} className={styles.ModelInfoContent}>
            <Text
              weight={800}
              casing="uppercase"
              fontSize="var(--font-h6)"
              color="#fff"
            >
              {selectedModel?.name}
            </Text>
            {/* <Text fontSize="var(--font-accent)">
              {MODEL_IMAGES[selectedModel.name]?.description}
            </Text> */}

            <Text
              weight={600}
              fontSize="var(--font-accent)"
              className="mt-3"
              color="#fff"
            >
              Available Controls:
            </Text>
            <Flex gap={12}>
              {Object.keys(IconMap).map((control) => {
                const Icon = IconMap[control as keyof typeof IconMap];
                return (
                  <Icon
                    key={control}
                    className="text-white h-auto"
                    width={22}
                    style={{
                      opacity: availableControls.includes(control) ? 1 : 0.3,
                    }}
                  />
                );
              })}
            </Flex>
          </Flex.Column>
        </motion.div>
      )}

      <ArtboardPresetsBlock />
      <Flex.Column gap={18} className="mt-2">
        <div className={styles.ModelsUpsell}>
          <Text fontSize="var(--font-accent)" weight={800}>
            Customized enterprise solutions
          </Text>
          <Text fontSize="var(--font-accent)" className="opacity-70">
            Boost your team’s creativity with a custom-built AI model, designed
            and trained for your organization.
          </Text>
          <CallToAction.a
            size="sm"
            className="w-full"
            variant="secondary"
            href="https://cal.com/dariuslam/nex-enterprise-demo-30-minutes"
          >
            Schedule a call
          </CallToAction.a>
        </div>
      </Flex.Column>
    </Flex.Column>
  );
};
