import React, { useCallback } from 'react';

import {
  CallToAction,
  Flex,
  Heading,
  Modal,
  truncateText,
  useToast,
} from '@nex/labs';

import analytics from '@lib/analytics';

import FolderIcon from '@nex/icons/svg/misc/folder.svg';

import { usePostHog } from 'posthog-js/react';

import { Folders } from '@/features/console';
import {
  useUpdateFileMutation,
  useUpdateFolderMutation,
} from '@/state/query/block';

export const CreateFolderModal = ({
  show,
  onClose,
  type,
  data,
}: {
  show: boolean;
  type?: any;
  data?: any;
  onClose: () => void;
}) => {
  const { createToast } = useToast();

  const posthog = usePostHog();

  const handleClose = useCallback(() => {
    return onClose();
  }, [onClose]);

  const { mutate: updateFile, isLoading: isUpdatingFileLoading } =
    useUpdateFileMutation({
      onSuccess: (_data) => {
        posthog.capture('folder_added_generation');

        createToast({
          message: 'File added to folder successfully',
          variant: 'secondary',
        });

        handleClose();
      },
      onError: (err: any) => {
        posthog.capture('folder_added_generation_error');

        createToast({
          message: err?.message ?? 'Something went wrong',
          variant: 'error',
        });
      },
    });

  const { mutate: updateFolder, isLoading: isUpdatingFolderLoading } =
    useUpdateFolderMutation({
      onSuccess: (_data) => {
        posthog.capture('folder_added_folder');

        createToast({
          message: 'Folder Moved successfully',
          variant: 'secondary',
        });

        handleClose();
      },
      onError: (err: any) => {
        posthog.capture('folder_added_folder_error');

        createToast({
          message: err?.message ?? 'Something went wrong',
          variant: 'error',
        });
      },
    });

  const setGenerations = (data: any) => {
    return [
      data?.images?.length > 0 ? data?.images?.map((d: any) => d.id) : data?.id,
    ]
      .flat()
      .filter(Boolean);
  };

  return (
    <Modal in={show} onClose={handleClose} size="md" title="Folder">
      <>
        <Heading.h6 weight={700} className="mb-5">
          <FolderIcon width={18} className="inline" /> Move &apos;
          {truncateText(data?.name, 30)}&apos; to a folder
        </Heading.h6>
        <div className="min-w-[400px]">
          <Folders
            bare
            view="list"
            folderBind={(folder_data: any) => (
              <Flex
                className="mt-10"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gap={12}
              >
                <CallToAction.button
                  size="sm"
                  isLoading={isUpdatingFileLoading}
                  onClick={() => {
                    if (type === 'folder') {
                      updateFolder({
                        id: data?.id,
                        data: {
                          folderId: folder_data?.folder?.id || 'root',
                        },
                      });
                      return;
                    }

                    updateFile({
                      id: data?.id,
                      data: {
                        folderId: folder_data?.folder?.id || 'root',
                      },
                    });
                  }}
                >
                  Add to (
                  {truncateText(folder_data?.folder?.name || 'root', 10)})
                  Folder
                </CallToAction.button>
                <CallToAction.button
                  size="sm"
                  variant="secondary"
                  isLoading={isUpdatingFileLoading || isUpdatingFolderLoading}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Close
                </CallToAction.button>
              </Flex>
            )}
          />
        </div>
      </>
    </Modal>
  );
};
