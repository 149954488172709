import { CallToAction, Flex, getDataIcons, pluralize, Text } from '@nex/labs';
import React, { use, useEffect, useMemo, useState } from 'react';
import ClockIcon from '@nex/icons/svg/misc/clock.svg';
import UploadIcon from '@nex/icons/svg/misc/upload.svg';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import { StorageDrawer } from './storage-drawer';
import styles from './storage.module.scss';
import { useGlobalStore } from '@/state/useStore';
import { StatusEnum } from '@nex/types';
import classNames from 'classnames';
export const Storage = () => {
  const [show, setShow] = useState(false);
  const { upload } = useGlobalStore();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setIsUploading(Object.keys(upload?.progress || {}).length > 0);
  }, [upload?.progress]);

  return (
    <>
      <StorageDrawer
        data={upload?.progress}
        show={show}
        onClose={() => setShow(false)}
      />
      <Flex.Column
        alignItems="center"
        justifyContent="flex-end"
        className={classNames([
          'mt-auto ',
          isUploading && 'bg-[var(--primary-gray)] p-[8px] rounded-[6px]',
        ])}
        fullWidth
        gap={14}
      >
        {isUploading ? (
          <UploadingComponent
            closeIsUploading={() => setIsUploading(false)}
            showSidebar={() => setShow(!show)}
            data={upload}
          />
        ) : (
          <IdleUpload showSidebar={() => setShow(!show)} />
        )}
      </Flex.Column>
    </>
  );
};

const UploadingComponent = ({
  showSidebar,
  data,
  closeIsUploading,
}: {
  showSidebar: () => void;
  data: Record<string, number>;
  closeIsUploading?: () => void;
}) => {
  const overallProgress =
    Object.values(data?.progress || {}).reduce((sum, upload) => {
      const fileProgress = [StatusEnum.DONE, StatusEnum.FAILED].includes(
        upload.status
      )
        ? 100
        : upload.progress;
      return sum + (fileProgress || 0);
    }, 0) / data?.count;

  const message = useMemo(() => {
    if (overallProgress === 100) {
      return `${pluralize('file', data?.count)}  uploaded`;
    }

    return `Uploading ${data?.count} items`;
  }, [data.count, overallProgress]);

  return (
    <>
      <Flex fullWidth justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" gap={4}>
          <LogoMark width={12} height={12} />
          <Text fontSize="var(--font-caption)" weight={600}>
            Status
          </Text>
        </Flex>
        <button className="text-right float-right" onClick={closeIsUploading}>
          <Text
            fontSize="var(--font-caption)"
            weight={500}
            decoration="underline"
          >
            <img src={getDataIcons('close')} width={10} height={10} />
          </Text>
        </button>
      </Flex>
      <Flex gap={4} alignItems="center">
        <UploadIcon />
        <Text weight={600}>{message}</Text>
      </Flex>
      <div className="h-[4px] bg-gray-200 rounded-full overflow-hidden w-full">
        <div
          className={styles.AnimateProgress}
          style={{ width: `${overallProgress || 100}%` }}
        />
      </div>

      <CallToAction.button
        size="sm"
        className="w-full"
        variant="clear"
        outline
        onClick={showSidebar}
      >
        View
      </CallToAction.button>
    </>
  );
};

const IdleUpload = ({ showSidebar }: { showSidebar: () => void }) => {
  return (
    <>
      <Flex gap={4} alignItems="center">
        <StorageCalculator />
        <Text weight={600}>17 GB of 100 GB used</Text>
      </Flex>

      <Text>
        <CallToAction.button
          size="sm"
          className="w-full"
          variant="clear"
          outline
          onClick={showSidebar}
          leadingIcon={<ClockIcon width={14} />}
        >
          Upload History
        </CallToAction.button>
      </Text>
    </>
  );
};
const StorageCalculator = ({ usedStorage = 17, totalStorage = 100 }) => {
  const percentage = (usedStorage / totalStorage) * 100;

  const angle = (percentage / 100) * 2 * Math.PI;

  const centerX = 8;
  const centerY = 8;
  const radius = 5;

  const endX = centerX + radius * Math.sin(angle);
  const endY = centerY - radius * Math.cos(angle);

  const largeArcFlag = percentage > 50 ? 1 : 0;

  const arcPath = `
    M ${centerX},${centerY - radius}
    A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
    L ${centerX},${centerY}
    Z
  `;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_790_8297)">
        <path
          d="M8.00002 1.47292C4.39544 1.47292 1.47335 4.3952 1.47335 8.00004C1.47335 11.6049 4.39543 14.5272 8.00002 14.5272C11.6046 14.5272 14.5267 11.6049 14.5267 8.00004C14.5267 4.3952 11.6046 1.47292 8.00002 1.47292ZM1.40668 8.00004C1.40668 4.35839 4.35861 1.40625 8.00002 1.40625C11.6414 1.40625 14.5933 4.35839 14.5933 8.00004C14.5933 11.6417 11.6414 14.5939 8.00002 14.5939C4.35862 14.5939 1.40668 11.6417 1.40668 8.00004Z"
          fill="#09090B"
          stroke="#09090B"
        />

        <path d={arcPath} fill="#09090B" />
      </g>
      <defs>
        <clipPath id="clip0_790_8297">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
