import React, { useEffect } from 'react';

import classNames from 'classnames';

import { Flex, Tooltip, useToast } from '@nex/labs';
import { usePostHog } from 'posthog-js/react';
import analytics from '@lib/analytics';

import { ImageReactionType } from '@nex/types/lib/prompt';

import { useUserStore } from '@/state/useStore';

import Thumbs from '@nex/icons/svg/misc/thumbs-up-alt.svg';

import { useReactToImageMutation } from '@/state/query/prompt';

import { Image } from '@nex/types/lib/artboard';

export const Vote = ({ data }: { data: Image }) => {
  const { createToast } = useToast();
  const posthog = usePostHog();
  const [reaction, setReaction] = React.useState<ImageReactionType | undefined>(
    data?.reaction
  );
  const { profile } = useUserStore();

  const { mutate: react, isLoading } = useReactToImageMutation({
    onSuccess: () => {
      analytics.track('User Reacted', {
        eventAction: `User Voted ${reaction}`,
        eventLabel: `User reacted to ${data?.id} - ${data?.url}`,
        eventValue: `${data?.id} - ${data?.url}`,
      });
      posthog.capture('prompt_generation_reaction', {
        reaction: data.reaction,
      });

      createToast({
        message: 'Thank you for your feedback!',
      });
    },
    onError: (err: any) => {
      createToast({
        message: err?.message ?? 'Something went wrong',
        variant: 'error',
      });
      setReaction(data?.reaction);
    },
  });

  useEffect(() => {
    setReaction(data?.reaction);
  }, [data]);

  return (
    <Tooltip
      tooltip={
        'This helps us improve our AI and provide better results for you.'
      }
    >
      <Flex gap={12} alignItems="center" className="ml-3">
        <button
          type="button"
          onClick={() => {
            if (reaction === ImageReactionType.LIKE) return;
            setReaction(ImageReactionType.LIKE);
            react({
              imageId: data?.id,
              workspaceId: profile?.organizationId!,
              reaction: ImageReactionType.LIKE,
            });
          }}
          disabled={isLoading}
        >
          <Thumbs
            className={classNames([
              'opacity-70',
              reaction === ImageReactionType.LIKE &&
                'text-green-500 opacity-100',
            ])}
            width={20}
          />
        </button>

        <button
          type="button"
          onClick={() => {
            if (reaction === ImageReactionType.DISLIKE) return;
            setReaction(ImageReactionType.DISLIKE);
            react({
              imageId: data?.id,
              workspaceId: profile?.organizationId!,
              reaction: ImageReactionType.DISLIKE,
            });
          }}
          disabled={isLoading}
        >
          <Thumbs
            className="rotate-180 opacity-70"
            color={reaction === ImageReactionType.DISLIKE ? 'red' : '#000'}
            width={20}
          />
        </button>
      </Flex>
    </Tooltip>
  );
};
