import { useGlobalStore } from '@/state/useStore';
import { useFeedback } from '@nex/labs';
import { useCallback } from 'react';

export const useFileUpload = (uploadOptions?: {
  autoAdd: boolean;
  block?: string;
  onUpload?: (e: any, key: string) => void;
}) => {
  const { setAssets, assets, setModal, modal, closeModal } = useGlobalStore();
  const { createDisclosure } = useFeedback();
  const launchModal = useCallback(() => {
    closeModal();

    setModal({
      type: 'assets',
      props: {
        title: 'Upload Files',
        size: 'lg',
        options: {
          ...uploadOptions,
        },
        onClose: async () => {
          await createDisclosure({
            title: 'Upload Files',
            message: 'Are you sure you want to close this modal?',
            confirmText: 'Close',
          });

          setAssets([]);
        },
      },
    });
  }, [uploadOptions, modal, uploadOptions]);

  const onFileUpload = useCallback(
    (files: any, options: any) => {
      if (!files || files.length === 0) return;

      if (modal.type !== 'assets') {
        launchModal();
      }

      setAssets({
        ...assets,
        ...files.reduce((acc: any, file: File, index: number) => {
          const assetKey = file?.name || `image-${assets!.length}`;

          return {
            ...acc,
            [assetKey]: {
              file: file as any,
              preview: options.previews[index] as string,
              folderInfo: options.folderInfo?.[index] as any,
            },
          };
        }, {}),
      });
    },
    [launchModal, assets, modal]
  );

  return { onFileUpload, launchModal };
};
