import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { useGlobalStore, useUserStore } from '@/state/useStore';
import {
  useAuth,
  useClerk,
  useOrganization,
  useOrganizationList,
  useUser,
} from '@clerk/nextjs';
import { Spinner } from '@nex/labs';
import Router from 'next/router';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { usePostHog } from 'posthog-js/react';

import { PaceLoader } from '../misc/pace-loader';
import { Seo } from '../seo';
import { AuthLayout } from './views/auth-layout';
import { ConsoleLayout } from './views/console-layout';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetWorkspaceQuery } from '@/state/query/user';
import { ArtboardLayout } from './views/artboard-layout';
import { AnimatorLayout } from './views/animator-layout';
import { GlobalModal } from './components/global-modal';

import PostHogPageView from '../misc/post-hog-view';

// import { GlobalModal } from './components/global-modal';

export const Layouts = {
  auth: AuthLayout,
  console: ConsoleLayout,
  artboard: ArtboardLayout,
  animator: AnimatorLayout,
};

export const PreRenderLayout = ({
  children,
  layout,
  seo,
}: {
  children: React.ReactNode;
  layout: keyof typeof Layouts;
  seo?: {
    title?: string;
    description?: string;
    image?: string;
  };
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams() || {
    get: () => null,
  };
  const auth = useAuth();

  const { overlayLoading, setOverlayLoading, setModal } = useGlobalStore();
  const { profile, logOut, setUser, setProfileLoading } = useUserStore();
  const { signOut } = useClerk();
  const { isLoaded: isOrgLoaded, organization } = useOrganization();
  const { isLoaded: organizationListIsLoaded } = useOrganizationList();
  const { user, isLoaded } = useUser();

  const { isLoading, isFetching } = useGetWorkspaceQuery(
    {
      workspaceId: organization?.publicMetadata?.externalId,
    },
    {
      enabled:
        !!Cookies.get('__session') &&
        !!organization?.publicMetadata?.externalId &&
        typeof organization !== 'undefined',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 3,
      cacheTime: 500,
      onSettled: (data) => {
        if (!user || !data?.workspace) return;

        setUser({
          user: {
            name: user.fullName!,
            email: user.primaryEmailAddress?.emailAddress,
            verified: true,
            photo: user.imageUrl,
            id: user.externalId!,
            organizationId: organization?.publicMetadata?.externalId as string,
            credit: data.workspace?.credit,
            onboardingChecklist: data.workspace?.onboardingChecklist,
            totalCredited: data.workspace?.totalCredited,
            subscription: data.workspace?.subscription,
          },
          update: true,
        });
      },

      onError: (err: any) => {
        if (err?.statusCode === 401) {
          return logOut();
        }
      },
    }
  );

  useEffect(() => {
    setProfileLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  const { setActive: setActiveWorkspace, userMemberships } =
    useOrganizationList({
      userMemberships: {
        infinite: true,
      },
    });

  useEffect(() => {
    if (isOrgLoaded && !organization) {
      const userMembership =
        !!userMemberships?.data?.length &&
        userMemberships?.data[0].organization?.id;

      if (!userMemberships?.isLoading && userMembership) {
        setActiveWorkspace?.({ organization: userMembership }).then(() => {
          router.refresh();
        });
      }
    }
  }, [
    userMemberships?.isLoading,
    userMemberships?.data?.length,
    isOrgLoaded,
    organization,
    setActiveWorkspace,
    userMemberships?.data,
  ]);

  useEffect(() => {
    if (['/onboarding', '/create-account'].includes(pathname!)) return;

    if (!userMemberships?.isLoading && userMemberships?.data?.length === 0) {
      setModal({
        type: 'createWorkspace',
        props: {
          title: null,
          size: 'md',
          dismissible: !!organizationListIsLoaded && auth.orgId,
        },
      });
    }
  }, [
    pathname,
    userMemberships?.isLoading,
    userMemberships?.data?.length,
    organizationListIsLoaded,
    auth.orgId,
  ]);

  useEffect(() => {
    if (user && isOrgLoaded && isLoaded) {
      setUser({
        user: {
          name: user?.fullName!,
          email: user?.primaryEmailAddress?.emailAddress,
          verified: true,
          photo: user?.imageUrl,
          id: user?.externalId!,
          organizationId: organization?.publicMetadata?.externalId as string,
        },
        update: true,
      });
    }

    if (!user && isLoaded) {
      setUser({ user: {} });
    }
  }, [user, isLoaded, organization?.publicMetadata?.externalId, isOrgLoaded]);

  const posthog = usePostHog();

  useEffect(() => {
    const session = Cookies.get('__session');

    if (!user && isLoaded && session) {
      logOut();
      posthog.reset();

      signOut({
        redirectUrl: '/login',
      });

      setTimeout(() => {
        setOverlayLoading({
          loading: false,
        });
        router.push('/login');
      }, 1000);
    }
  }, [profile?.id, user, isLoaded]);

  useEffect(() => {
    const getTref = searchParams.get('tref')?.toString();

    if (getTref) {
      window.sessionStorage.setItem('tref', getTref);

      posthog.identify(posthog.get_distinct_id(), {
        $set: {
          tref: getTref || 'nex',
        },
      });
    }
  }, [posthog, searchParams.get('tref')]);

  const motionKey = pathname?.split('/')[1];

  const ComponentLayout = Layouts[layout] || React.Fragment;

  return (
    <>
      <ComponentLayout>
        {seo?.title && (
          <Seo
            title={seo?.title}
            description={seo?.description}
            ogImage={seo?.image}
          />
        )}

        <PostHogPageView />
        {overlayLoading?.loading && (
          <Spinner
            fullPage
            text={overlayLoading?.message || 'Please wait…'}
            textProps={{
              color: 'white',
            }}
            color="#fff"
          />
        )}
        {!pathname?.includes('/generate') && <PaceLoader />}
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            key={motionKey}
            initial={{ opacity: 0, y: 4 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 4 }}
            transition={{
              duration: 0.2,
              ease: 'easeInOut',
            }}
          >
            {children}
          </motion.div>
        </AnimatePresence>

        <GlobalModal />
      </ComponentLayout>
    </>
  );
};
