import { MyStateCreator } from './store';

type MarkoData = {
  context: Array<Record<string, any>>;
};

export type MarkoSlice = {
  context: MarkoData['context'];
  actions?: {
    setMarkoData: (data: MarkoData['context']) => void;
    removeMarkoData: (data: MarkoData['context']) => void;
    resetMarkoData: () => void;
  };
};

export const createMarkoSlice: MyStateCreator<MarkoSlice> = (set, get) => ({
  context: [],
  actions: {
    setMarkoData: (data: any) => {
      const prevData = get().marko.context;
      set((state) => {
        state.marko.context = [
          data,
          ...prevData.filter((item) => item.id !== data.id),
        ];
      });
    },

    removeMarkoData: (data: any) => {
      const prevData = get().marko.context;
      set((state) => {
        state.marko.context = prevData.filter((item) => item.id !== data.id);
      });
    },

    resetMarkoData: () => {
      set((state) => {
        state.marko.context = [];
      });
    },
  },
});
