import React, { useEffect } from 'react';
import {
  Avatar,
  CallToAction,
  Flex,
  Heading,
  Img,
  Text,
  extractImage,
  pluralize,
  useDebounce,
  getDataIcons,
} from '@nex/labs';
import { formatDistanceToNow } from 'date-fns';
import Link from 'next/link';

import { useUserStore } from '@/state/useStore';
import { FetchContainer } from '@/components/misc/fetch-container';

import Bookmark from '@nex/icons/svg/misc/structure.svg';

import { CreateFolderModal } from '@/components/layout';
import { useGetCanvasesQuery } from '@/state/query/canvas';
import styles from '../library.module.scss';
import { AppIcon } from '@/components/misc/app-icon';

interface CanvasProps {
  query?: string;
  bare?: boolean;
  isSearch?: boolean | string;
  limit?: number;
}

export const Canvases = ({ query, bare, isSearch, limit }: CanvasProps) => {
  const searchValue = useDebounce(query, 500);
  const { profile } = useUserStore();

  const { data, isLoading, fetchNextPage, hasNextPage } = useGetCanvasesQuery(
    {
      search: searchValue as string,
      limit: limit || 15,
      workspaceId: profile?.organizationId,
    },
    {
      enabled: !!profile?.organizationId,
    }
  );

  return (
    <div className="mt-1">
      <div className={styles.ConsoleWrapper}>
        <div className={styles.ConsoleRecentWorks}>
          <FetchContainer
            isLoading={isLoading}
            hasMore={hasNextPage}
            fetchNextPage={
              typeof limit === 'undefined' ? fetchNextPage : undefined
            }
            shouldBeEmpty={!data?.canvases?.length}
            emptyMessage={
              isSearch
                ? 'No canvas found matching your search'
                : 'You have no canvas yet. Add your first artboard!'
            }
          >
            {!isSearch && !bare && (
              <>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  gap={12}
                  alignItems="center"
                >
                  <span>
                    <Flex gap={4}>
                      <Bookmark width={14} className="opacity-80" />{' '}
                      <Heading.h5 weight={700}>Canvas</Heading.h5>
                    </Flex>
                  </span>

                  <CallToAction.a
                    size="xs"
                    className="h-fit"
                    variant="secondary"
                    href="/canvas/new"
                    leadingIcon={<img src={getDataIcons('add', '#000')} />}
                  >
                    New Canvas
                  </CallToAction.a>
                </Flex>
              </>
            )}

            {data?.canvases?.length ? (
              <div className={styles.ArtboardGrid}>
                {data?.canvases
                  ?.slice(0, limit || data?.canvases?.length)
                  ?.map((canvas: any, index: number) => (
                    <Link
                      href={`/canvas/${canvas?.id}`}
                      key={canvas?.id}
                      className={styles.ConsoleArtboardsArea__card}
                    >
                      <Img
                        src={canvas?.thumbnail || '/images/empty_image.png'}
                        alt="artboard"
                        className="h-[200px] basis-[100%] w-full rounded-md object-cover object-center border-[1px] border-[var(--primary-gray)] border-solid"
                      />
                      <div
                        className={styles.ConsoleArtboardsArea__card__content}
                      >
                        <Flex.Row alignItems="center" gap={8}>
                          <AppIcon type="canvas" width={18} color="#000" />
                          <Heading.h5 weight={700}> {canvas?.title}</Heading.h5>
                        </Flex.Row>
                        <Flex.Row gap={8} alignItems="center">
                          <Avatar
                            size={24}
                            alt={canvas?.creator?.name}
                            src={canvas?.creator?.photo}
                          />
                          <Text>
                            {canvas?.updatedAt && (
                              <Text.span
                                className="opacity-70 mt-1"
                                fontSize="12px"
                              >
                                Updated{' '}
                                {formatDistanceToNow(
                                  new Date(canvas?.updatedAt)
                                )}
                                {' ago'}
                              </Text.span>
                            )}
                          </Text>
                        </Flex.Row>
                      </div>
                    </Link>
                  ))}

                <Link
                  href="/canvas/new"
                  className={styles['ConsoleArtboardsArea__card--add']}
                >
                  <span>
                    <img src={getDataIcons('add')} />
                  </span>
                  <Text weight={600}>New Canvas</Text>
                </Link>
              </div>
            ) : null}
          </FetchContainer>
        </div>
      </div>
    </div>
  );
};
