import {
  CallToAction,
  Flex,
  Heading,
  Popover,
  Tabs,
  capitalize,
  getDataIcons,
} from '@nex/labs';
import Router, { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import querystring from 'query-string';
import { Folders } from '../library/views/folders';

import { AssetsView } from '../library/views/assets';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import styles from '../library/library.module.scss';

import GridIcon from '@nex/icons/svg/misc/grid.svg';
import ListIcon from '@nex/icons/svg/misc/list.svg';
import FolderIcon from '@nex/icons/svg/misc/folder.svg';

import { useFileUpload } from '@/components/upload/useUpload';
import { DragDropFileUpload } from '@/components/upload/drag-drop';
import { SortButton } from '../library/components/sort-button';
import { StrictModeDroppable } from '@/features/ai-canvas/components/misc/sm-droppable';
import Breadcrumb from '@/components/misc/bread-crumb';
import { CreateFolderModal } from '@/components/layout';
import { useQueryData } from '@/utils/hooks/useQueryData';
import { blockQueryKeys } from '@/state/query/block';
import { min } from 'date-fns';
import { useUserStore } from '@/state/useStore';
import classNames from 'classnames';

export const Assets = ({
  id,
  defaultView = 'grid',
  bare,
  onContextSelect,
}: {
  id?: string;
  defaultView?: 'grid' | 'list';
  onContextSelect?: (context: string) => void;
  bare?: boolean;
}) => {
  const router = useRouter();

  const [currentTab, setCurrentTab] = React.useState('all');
  const [currentView, setCurrentView] = React.useState<'grid' | 'list'>(
    defaultView ?? 'grid'
  );
  const { profile } = useUserStore();

  const filter = {
    mine: `creator:${profile?.id}`,
    external: `creator:!${profile?.id}`,
  };

  const { data } = useQueryData(
    [
      ...blockQueryKeys.getUserFolders,
      {
        folderId: Router.query.folderId,
        limit: 15,
        sort: Router.query.sort,
        filter: filter[currentTab as 'mine' | 'external'],
      },
    ],
    { isInfinite: true, enabled: !!Router.query.folderId }
  );

  const { onFileUpload, launchModal } = useFileUpload();
  const [createNew, setCreateNew] = React.useState(false);

  const getHref = (tab: string) => {
    return querystring.stringifyUrl({
      url: router.pathname,
      query: { ...router.query, tab },
    });
  };

  useEffect(() => {
    const { tab } = router.query;
    if (tab) {
      setCurrentTab(tab as string);
    }
  }, [router.query]);

  const handleDragEnd = useCallback((result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    // The draggableId will be the asset ID
    // The destination.droppableId will be the collection ID
    // Here you would implement your move asset logic
    const assetId = draggableId;
    const collectionId = destination.droppableId;

    // Call your mutation here
    // moveAssetMutation.mutate({ assetId, collectionId });
  }, []);

  const items = useMemo(() => {
    const folder = data?.[0]?.folder;
    return [
      { label: 'Home', href: '/assets' },
      ...(!!folder
        ? folder?.trail
            .map((folder: { name: string; id: string }) => ({
              label: folder.name,
              href: `/assets/folders/${folder.id}`,
            }))
            .reverse()
        : []),
      {
        label: folder?.name,
      },
    ];
  }, [data]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={classNames([styles.ConsoleWrapper, 'w-full'])}>
        <CallToAction.upload
          onFileUpload={onFileUpload}
          beforeFileValidation={launchModal}
        />

        <Flex.Row gap={24} className="mt-4" justifyContent="space-between">
          <Heading.h4
            fontSize="calc(var(--font-h4) - 2px)"
            className="mb-1 flex items-center gap-2"
            weight={700}
          >
            <LogoMark className="inline w-[32px] h-[32px]" />
            {!id ? (
              <>
                <span className="font-light opacity-80">Assets /</span>
                <span className="font-bold">{capitalize(currentTab)}</span>
              </>
            ) : (
              <Breadcrumb items={items} />
            )}
            <br />
          </Heading.h4>
          <Flex.Row gap={12} alignItems="center">
            <CallToAction
              variant="secondary"
              size="sm"
              onClick={() => setCreateNew(true)}
            >
              New Folder
            </CallToAction>
            <Popover size="sm">
              <Popover.Trigger>
                <CallToAction
                  size="xs"
                  className="h-fit"
                  variant="secondary"
                  leadingIcon={<img src={getDataIcons('add', '#000')} />}
                >
                  Upload
                </CallToAction>
              </Popover.Trigger>
              <Popover.Content isDropdown>
                <CallToAction.input
                  size="xs"
                  className="h-fit p-0"
                  variant="clear"
                  leadingIcon={<img src={getDataIcons('add', '#000')} />}
                  defaultBehavior={false}
                  onFileUpload={onFileUpload}
                  beforeFileValidation={launchModal}
                  multiple
                  acceptedFileTypes={['image/*', 'video/*']}
                >
                  New File Upload
                </CallToAction.input>
                <CallToAction.input
                  size="xs"
                  className="h-fit p-0"
                  variant="clear"
                  leadingIcon={<FolderIcon width={18} className="is-stroke" />}
                  defaultBehavior={false}
                  onFileUpload={onFileUpload}
                  multiple
                  beforeFileValidation={launchModal}
                  acceptedFileTypes={['image/*', 'video/*']}
                  isFolder
                >
                  New Folder Upload
                </CallToAction.input>
              </Popover.Content>
            </Popover>
          </Flex.Row>
        </Flex.Row>
        <Flex.Column gap={24} className="mt-[24px]">
          <Flex.Column gap={24}>
            <Flex.Row gap={24}>
              {!id && (
                <Tabs
                  selectedKey={currentTab}
                  defaultTab="all"
                  size="sm"
                  centered={false}
                >
                  <Tabs.Tab dataKey="all" title="All" href={getHref('all')} />
                  <Tabs.Tab
                    dataKey="mine"
                    title="Mine"
                    href={getHref('mine')}
                  />
                  <Tabs.Tab
                    dataKey="external"
                    title="External"
                    href={getHref('external')}
                  />

                  {/* <Tabs.Tab
                dataKey="collections"
                title="Folders"
                href={getHref('collections')}
              /> */}
                </Tabs>
              )}
              <Tabs
                defaultTab={defaultView}
                size="sm"
                centered={false}
                onChangeTab={(tab) => {
                  setCurrentView(tab as 'grid' | 'list');
                }}
              >
                <Tabs.Tab
                  dataKey="grid"
                  title={
                    <Flex
                      alignItems="center"
                      gap={6}
                      className="relative z-[1]"
                    >
                      <GridIcon color="#000" width={18} height={24} />
                    </Flex>
                  }
                />

                <Tabs.Tab
                  dataKey="list"
                  title={
                    <Flex
                      alignItems="center"
                      gap={6}
                      className="relative z-[1]"
                    >
                      <ListIcon color="#000" width={18} height={24} />
                    </Flex>
                  }
                />
              </Tabs>
              <SortButton />
            </Flex.Row>

            <Flex.Column gap={24}>
              <Folders
                view={currentView}
                bare={bare}
                onContextSelect={onContextSelect}
                createNewFolder={() => setCreateNew(true)}
              />
              {!bare && (
                <StrictModeDroppable droppableId="assets">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      // className={classNames([
                      //   styles.FolderArea__card,
                      //   { [styles.isDropTarget]: snapshot.isDraggingOver },
                      // ])}
                    >
                      <AssetsView view={currentView} />
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              )}

              <CreateFolderModal
                show={createNew}
                onClose={() => setCreateNew(false)}
                type="create"
              />
            </Flex.Column>
          </Flex.Column>
        </Flex.Column>
      </div>
    </DragDropContext>
  );
};
