import { CallToAction, Flex, Popover } from '@nex/labs';
import { useState } from 'react';
import SortIcon from '@nex/icons/svg/misc/sort-filter.svg';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import queryString from 'query-string';

export const SortButton = ({
  onSortChange,
}: {
  onSortChange?: (val?: string) => void;
}) => {
  const sortOptions = {
    default: {
      title: 'Default',
      key: '',
    },
    name: {
      title: 'Name',
      key: 'name',
    },
    createdAt: {
      title: 'Date Created',
      key: 'createdAt',
    },
    size: {
      title: 'Size',
      key: 'size',
    },
  } as Record<
    string,
    {
      title: string;
      key: string;
    }
  >;

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queries = queryString.parse(searchParams?.toString()!);
  const [currentSort, setCurrentSort] = useState('name');
  const [currentOrder, setCurrentOrder] = useState('desc');

  return (
    <Flex gap={12}>
      <Popover hug>
        <Popover.Trigger>
          <CallToAction size="sm" variant="secondary">
            Sort by: {sortOptions[currentSort].title}
          </CallToAction>
        </Popover.Trigger>
        <Popover.Content isDropdown>
          {Object.keys(sortOptions).map((sort) => (
            <button
              key={sort}
              onClick={() => {
                const sortKey = !sortOptions[sort].key
                  ? ''
                  : `${sortOptions[sort].key}:${currentOrder}`;
                onSortChange?.(sort);
                setCurrentSort(sort);
                router.replace(
                  queryString.stringifyUrl({
                    url: pathname!,
                    query: {
                      ...queries,
                      sort:
                        (queries?.sort as string)?.replace(
                          `:${currentOrder}`,
                          ''
                        ) === sortKey?.replace(`:${currentOrder}`, '')
                          ? undefined
                          : sortKey,
                    },
                  }),
                  { scroll: false }
                );
              }}
            >
              {sortOptions[sort].title}
            </button>
          ))}
        </Popover.Content>
      </Popover>
      <CallToAction
        size="sm"
        variant={currentOrder === 'asc' ? 'primary' : 'clear'}
        outline={currentOrder === 'desc'}
        onClick={() => {
          if (!queries?.sort) return;
          const order = currentOrder === 'asc' ? 'desc' : 'asc';
          setCurrentOrder(order);
          router.replace(
            queryString.stringifyUrl({
              url: pathname!,
              query: {
                ...queries,
                sort: `${(queries?.sort as string).replace(/:asc|:desc/, '')}:${currentOrder === 'asc' ? 'desc' : 'asc'}`,
              },
            }),
            { scroll: false }
          );
        }}
      >
        <SortIcon />
      </CallToAction>
    </Flex>
  );
};
