import React from 'react';
import styles from './storage.module.scss';
import {
  Empty,
  EphemeralPortal,
  Flex,
  getDataIcons,
  Heading,
  Spinner,
  Text,
  truncateText,
} from '@nex/labs';
import FolderIcon from '@nex/icons/svg/misc/folder.svg';
import CheckmarkIcon from '@nex/icons/svg/misc/check.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { RenderIf, RenderUnless } from '@/components/misc/render-if';
import Link from 'next/link';
import { useQueryData } from '@/utils/hooks/useQueryData';
import { blockQueryKeys } from '@/state/query/block';
import Router from 'next/router';
import { StatusEnum } from '@nex/types';

const FileStatus = {
  [StatusEnum.DONE]: CheckmarkIcon,
  [StatusEnum.FAILED]: () => (
    <img src={getDataIcons('close', 'red')} width={14} />
  ),
  [StatusEnum.PROCESSING]: ({ progress = 10 }) => (
    <>
      {progress ? (
        <div className="w-[50px] h-[4px] bg-gray-100 rounded-full">
          <div
            className="h-full bg-[var(--primary-theme)] rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
      ) : (
        <Spinner size={20} />
      )}
    </>
  ),
};

export const StorageDrawer = ({
  show,
  onClose,
  data,
}: {
  show: boolean;
  onClose: () => void;
  data: any;
}) => {
  const { data: filesData } = useQueryData(
    [
      ...blockQueryKeys.getUserFiles,
      { folderId: Router.query.folderId, limit: 15, sort: Router.query.sort },
    ],
    { isInfinite: true, enabled: !!Router.query.folderId }
  );

  const classifiedData = [
    {
      date: 'Recently Uploaded',
      data: Object.keys(data)?.map((file: string) => ({
        ...data[file],
        thumbnail: data[file]?.preview,
      })),
    },
  ];

  return (
    <EphemeralPortal>
      <AnimatePresence mode="wait">
        {show && (
          <>
            <motion.button
              className={styles.DrawerBackdrop}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={onClose}
            />
            <motion.div
              className={styles.StorageDrawer}
              initial={{ x: '-50%' }}
              animate={{ x: 0 }}
              exit={{ x: 10 }}
              transition={{
                duration: 0.2,
              }}
            >
              <Flex.Column gap={14}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Heading.h6 weight={600}>Uploads in Last 7 Days</Heading.h6>
                  <button onClick={onClose}>
                    <img src={getDataIcons('close')} width={12} />
                  </button>
                </Flex>
                <Flex.Column>
                  <RenderIf condition={!!classifiedData[0]?.data?.length}>
                    <DrawerImage data={classifiedData} />
                  </RenderIf>
                  <RenderUnless condition={!!classifiedData[0]?.data?.length}>
                    <Flex.Column className="mt-4 p-4 text-center">
                      <Empty
                        message="Upload progress and history will show up here"
                        size="sm"
                        stack
                      />
                    </Flex.Column>
                  </RenderUnless>
                </Flex.Column>
              </Flex.Column>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </EphemeralPortal>
  );
};

const DrawerImage = ({ data }: { data: any }) => {
  return (
    <Flex.Column gap={24}>
      {data.map((filePerDay: Record<string, any>) => (
        <Flex.Column gap={8} key={filePerDay.date}>
          <Text>{filePerDay?.date}</Text>
          <Flex.Column gap={4}>
            {filePerDay.data.map((file: Record<string, any>) => {
              const StatusComp = FileStatus[file.status as StatusEnum];
              return (
                <div className={styles.File} key={file.name}>
                  <Flex alignItems="center" gap={8}>
                    <img src={file.thumbnail} className={styles.FileImage} />
                    <Text weight={600} noOfLines={1}>
                      {truncateText(file.name, 20)}
                    </Text>
                  </Flex>

                  <Flex alignItems="center" gap={12}>
                    <StatusComp width={20} progress={file?.progress} />
                    {(data?.folder?.id || file.folderId) && (
                      <Link
                        href={`/assets/folders/${data?.folder?.id || file?.folderId}`}
                        className="bg-[var(--border-gray)] px-3 py-2 rounded-[4px]"
                      >
                        <FolderIcon width={16} />
                      </Link>
                    )}
                  </Flex>
                </div>
              );
            })}
          </Flex.Column>
        </Flex.Column>
      ))}
    </Flex.Column>
  );
};
