import React, { useMemo } from 'react';
import {
  CallToAction,
  Flex,
  Heading,
  Text,
  useDebounce,
  getDataIcons,
  extractImage,
  Img,
  pluralize,
} from '@nex/labs';
import Link from 'next/link';

import { formatDistanceToNow } from 'date-fns';
import { useGetCollectionQuery } from '@/state/query/prompt';
import { FetchContainer } from '@/components/misc/fetch-container';
import Bookmark from '@nex/icons/svg/misc/bookmark.svg';
import FolderIcon from '@nex/icons/svg/misc/folder.svg';

import { CreateFolderModal } from '@/components/layout';
import { useGlobalStore, useUserStore } from '@/state/useStore';

import styles from '../library.module.scss';
import { FolderCard } from '@/components/misc/folder-card';
import { useGetUserFoldersQuery } from '@/state/query/block';
import Router from 'next/router';
import Breadcrumb from '@/components/misc/bread-crumb';
import { AssetsView } from './assets';

export const Folders = ({
  query,
  bare,
  isSearch,
  limit,
  view,
  createNewFolder,
  onContextSelect,
  folderBind,
}: {
  query?: string;
  bare?: boolean;
  limit?: number;
  isSearch?: boolean | string;
  view?: 'grid' | 'list';
  createNewFolder?: () => void;
  onContextSelect?: (context: any) => void;
  folderBind?: (data: any) => React.ReactNode;
}) => {
  const searchValue = useDebounce(query, 500);
  const [folderId, setFolderId] = React.useState<string | null>(
    typeof folderBind === 'function' ? 'root' : null
  );

  const { profile } = useUserStore();

  const filter = {
    mine: `creator:${profile.id}`,
    external: `creator:!${profile.id}`,
  };

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useGetUserFoldersQuery({
      search: searchValue as string,
      limit: limit || 15,
      folderId: folderId || (Router.query.folderId as string),
      sort: Router.query.sort as string,
      filter: filter[Router.query.tab as 'mine' | 'external'],
    });

  const items = useMemo(() => {
    const folder = data?.folder;
    return [
      {
        label: 'Home',
        onClick: () => {
          setFolderId('root');
        },
      },
      ...(!!folder
        ? folder?.trail
            .map((folder: { name: string; id: string }) => ({
              label: folder.name,
              onClick: () => setFolderId(folder.id),
            }))
            .reverse()
        : []),
      folder
        ? {
            label: folder?.name,
          }
        : null,
    ].filter(Boolean);
  }, [data]);

  return (
    <div className={styles.ConsoleWrapper}>
      <div className={styles.ConsoleRecentWorks}>
        <FetchContainer
          isLoading={isLoading}
          isBackgroundLoading={isFetching}
          hasMore={hasNextPage}
          fetchNextPage={fetchNextPage}
          shouldBeEmpty={!data?.folders?.length}
          closeChildrenOnEmpty={!!Router.query.folderId && !bare}
          emptyProps={
            bare
              ? {
                  size: 'sm',
                  img: <FolderIcon width={100} />,
                }
              : {}
          }
          emptyMessage={
            isSearch
              ? 'No folders found matching your search'
              : 'You have no folders yet.'
          }
        >
          {!isSearch && !bare && (
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              gap={12}
              alignItems="center"
            >
              <span>
                <Flex gap={4}>
                  <Heading.h6 weight={700}>Folders</Heading.h6>
                </Flex>
              </span>

              <CallToAction.button
                size="xs"
                className="h-fit"
                variant="secondary"
                leadingIcon={<img src={getDataIcons('add', '#000')} />}
                onClick={() => createNewFolder?.()}
              >
                New Folder
              </CallToAction.button>
            </Flex>
          )}

          {bare && <Breadcrumb items={items} />}

          <FolderCard
            data={data?.folders}
            limit={limit}
            view={view}
            type="asset"
            onContextSelect={onContextSelect}
            renderInline={bare}
            {...(bare
              ? {
                  onFolderClick: (data: { id: string }) =>
                    setFolderId(data?.id),
                }
              : {})}
          />
        </FetchContainer>

        {bare && typeof folderBind === 'undefined' && (
          <div className="mt-5">
            <AssetsView
              id={folderId || 'root'}
              bare
              view={view}
              onContextSelect={onContextSelect}
            />
          </div>
        )}

        {folderBind?.(data)}
      </div>
    </div>
  );
};
