import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Spinner,
  VisuallyHidden,
  Empty,
  Flex,
  CallToAction,
  useMediaQuery,
} from '@nex/labs';
import type { EmptyProps } from '@nex/labs';

type FetchContainerProps = {
  children: React.ReactNode;
  shouldBeEmpty?: boolean;
  emptyMessage?: string;
  isLoading?: boolean;
  emptyProps?: Omit<EmptyProps, 'message'>;
  closeChildOnLoading?: boolean;
  closeChildrenOnEmpty?: boolean;
  fetchNextPage?: () => void;
  hasMore?: boolean;
  skip?: boolean;
  useButton?: boolean;
  isBackgroundLoading?: boolean;
};

const FOOTER_HEIGHT = 310;
const THROTTLE_DELAY = 200; // ms

const useThrottle = (callback: () => void, delay: number) => {
  const lastRun = useRef(Date.now());
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(() => {
    const now = Date.now();
    if (now - lastRun.current >= delay) {
      callback();
      lastRun.current = now;
    } else {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(callback, delay);
    }
  }, [callback, delay]);
};

const useInfiniteScroll = (
  callback: () => void,
  options?: Partial<{
    hasMore: boolean;
    isRootLoading?: boolean;
    ref?: React.RefObject<HTMLElement>;
    skip?: boolean;
    useButton?: boolean;
  }>
) => {
  const { hasMore, isRootLoading, ref, skip, useButton } = options || {};
  const isMediaMatch = useMediaQuery('md', 'lessThan') || useButton;

  const isMediaMatchOrUseButton = useMemo(() => {
    if (skip) return false;
    if (useButton) return true;
    return !isMediaMatch;
  }, [useButton, isMediaMatch, skip]);

  const handleScroll = useThrottle(() => {
    if (skip || isRootLoading || !hasMore || isMediaMatchOrUseButton) return;

    const { scrollTop, scrollHeight, clientHeight } =
      ref?.current || document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - FOOTER_HEIGHT) {
      callback();
    }
  }, THROTTLE_DELAY);

  useEffect(() => {
    const scrollElement = ref?.current || window;
    scrollElement.addEventListener('scroll', handleScroll, { passive: true });
    return () => scrollElement.removeEventListener('scroll', handleScroll);
  }, [handleScroll, ref]);

  const InfiniteLoader = () => {
    if (!hasMore && useButton && !isRootLoading) return null;

    return isRootLoading ? (
      <Spinner
        center
        style={{ margin: '3rem 0 1rem' }}
        size={30}
        spinner="logo"
        text="Loading..."
      />
    ) : (
      isMediaMatchOrUseButton && hasMore && (
        <Flex justifyContent="center" css={{ margin: '1.5rem 0' }}>
          <CallToAction.button onClick={callback}>
            Load more
          </CallToAction.button>
        </Flex>
      )
    );
  };

  return { Loader: InfiniteLoader };
};

export const FetchContainer: React.FC<FetchContainerProps> = ({
  children,
  shouldBeEmpty,
  emptyMessage = 'No results found.',
  emptyProps,
  closeChildrenOnEmpty,
  closeChildOnLoading,
  isLoading,
  isBackgroundLoading,
  fetchNextPage,
  hasMore,
  skip,
  useButton,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const { Loader: InfiniteLoader } = useInfiniteScroll(
    () => fetchNextPage?.(),
    { hasMore, useButton, isRootLoading: isLoading, skip }
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.3 }
    );
    if (loaderRef.current) observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, []);

  if (
    closeChildrenOnEmpty &&
    shouldBeEmpty &&
    !isLoading &&
    !isBackgroundLoading
  )
    return null;

  return (
    <>
      {isBackgroundLoading && !isLoading && (
        <Spinner
          center
          snackbar
          size={30}
          spinner="logo"
          text="Please wait..."
        />
      )}

      {(!isVisible || !fetchNextPage) &&
        isLoading &&
        typeof isBackgroundLoading === 'undefined' && (
          <Spinner center size={30} spinner="logo" text="Loading…" />
        )}

      {closeChildOnLoading ? (
        <VisuallyHidden>{children}</VisuallyHidden>
      ) : (
        children
      )}

      <div ref={loaderRef}>
        {fetchNextPage ? (
          shouldBeEmpty && !isLoading && !isBackgroundLoading ? (
            <Empty message={emptyMessage} {...emptyProps} />
          ) : (
            <InfiniteLoader />
          )
        ) : !fetchNextPage && !isLoading && shouldBeEmpty ? (
          <Empty message={emptyMessage} />
        ) : null}
      </div>
    </>
  );
};

export default FetchContainer;
