import React, { Fragment, useEffect } from 'react';
import Router, { usePathname, useRouter } from 'next/navigation';
import {
  CallToAction,
  Flex,
  Text,
  useHover,
  transitions,
  Spacer,
  Popover,
} from '@nex/labs';

import Link from 'next/link';
import classNames from 'classnames';

import OverviewIcon from '@nex/icons/svg/console/sidebar/overview.svg';
import AssetsIcon from '@nex/icons/svg/dashboard/image.svg';
import ProjectsIcon from '@nex/icons/svg/console/sidebar/projects.svg';
import SessionIcon from '@nex/icons/svg/misc/chat.svg';
import BrandIcon from '@nex/icons/svg/console/sidebar/guideline.svg';
import AudienceIcon from '@nex/icons/svg/console/sidebar/audience.svg';
import KnowledgeIcon from '@nex/icons/svg/console/sidebar/knowledge.svg';

import PlusIcon from '@nex/icons/svg/blocks/plus.svg';
import { Storage } from './storage';

import styles from '../console-layout.module.scss';

const CONSOLE_LINKS = [
  {
    name: 'NEX Creative Hub',
    children: [
      {
        name: 'Assets',
        key: 'assets',
        href: '/assets',
        icon: AssetsIcon,
      },
      {
        name: 'Projects',
        key: 'projects',
        href: '/projects',
        icon: ProjectsIcon,
      },
    ],
  },
  {
    name: 'Marko',
    children: [
      {
        name: 'Sessions',
        key: 'sessions',
        href: '/marko/sessions',
        icon: SessionIcon,
      },
      {
        name: 'Brand Guidelines',
        key: 'brand-guidelines',
        href: '/marko/guidelines',
        icon: BrandIcon,
      },
      {
        name: 'Audiences',
        key: 'audiences',
        href: '/marko/audience',
        icon: AudienceIcon,
      },
      {
        name: 'Knowledge',
        key: 'knowledge',
        href: '/marko/knowledge',
        icon: KnowledgeIcon,
      },
    ],
  },
];

export const ConsoleSideBar = () => {
  const [openNav, setOpenNav] = React.useState(false);

  // useEffect(() => {
  //   Router.events.on('routeChangeComplete', () => {
  //     setOpenNav(false);
  //   });
  // }, []);

  const { hoverProps } = useHover({
    onHoverStart: (e) => setOpenNav(true),
    onHoverEnd: (e) => setOpenNav(false),
  });

  const router = useRouter();
  const pathname = usePathname();

  const getActive = (href: string) => {
    return pathname === href ? styles.active : '';
  };

  const getSlideIn = transitions.useSlide();

  return (
    <aside
      className={classNames([
        styles.ConsoleLayoutAside,
        openNav && styles.openNav,
      ])}
      {...(hoverProps as any)}
    >
      <Flex.Column
        gap={10}
        className={classNames([styles.ConsoleLayoutAsideMetaWrap, 'h-full'])}
      >
        <Popover size="sm">
          <Popover.Trigger>
            <div className={(!openNav && 'hidden xxl:block') || ''}>
              <CallToAction.button
                leadingIcon={<PlusIcon />}
                className="py-[8px] w-full m-auto xxl:w-[var(--sidebar-width)]"
                size="block"
              >
                <Text.span weight={600}>Create New</Text.span>
              </CallToAction.button>
            </div>
            <div className={(!openNav && 'block xxl:hidden') || 'hidden '}>
              <PlusIcon
                height="35"
                className="[
            &>path
          ] text-[var(--primary-theme)] stroke-current
          ]"
              />
            </div>
          </Popover.Trigger>
          <Popover.Content isDropdown>
            <Link href="/"></Link>
          </Popover.Content>
        </Popover>
        <Flex.Column gap={2} className={styles.ConsoleLayoutAsideNav}>
          <Link href="/" className={getActive('/')}>
            <OverviewIcon /> <Text.span>Overview</Text.span>
          </Link>
        </Flex.Column>

        {CONSOLE_LINKS.map((links, index) => {
          return (
            <Fragment key={index}>
              <Spacer divider size={1} direction="horizontal" />
              <Text weight={600} color="var(--gray)">
                {links.name}
              </Text>
              <Flex.Column gap={2} className={styles.ConsoleLayoutAsideNav}>
                {links.children.map((link) => {
                  const Component = link.icon;
                  return (
                    <Link
                      href={link.href}
                      key={link.key}
                      className={getActive(link.href)}
                    >
                      <Component className={styles.isStroke} />{' '}
                      <Text.span>{link.name}</Text.span>
                    </Link>
                  );
                })}
              </Flex.Column>
            </Fragment>
          );
        })}

        <Storage />
      </Flex.Column>
    </aside>
  );
};
