import {
  Avatar,
  CallToAction,
  Flex,
  Heading,
  Img,
  Popover,
  Text,
  extractFirstName,
  getDataIcons,
  truncateText,
  useFeedback,
} from '@nex/labs';
import classNames from 'classnames';

import styles from './asset-card.module.scss';
import { format, formatDistanceToNow } from 'date-fns';
import { AppIcon } from '@/components/misc/app-icon';
import { Draggable } from 'react-beautiful-dnd';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDeleteUserFileMutation } from '@/state/query/block';
import PlayIcon from '@nex/icons/svg/misc/play.svg';
import { CreateFolderModal } from '../../views/folders/modals/create-folder';
import { usePathname, useRouter } from 'next/navigation';
import queryString from 'query-string';
import { useVirtualizer } from '@tanstack/react-virtual';

export const AssetCard = ({
  setCurrentData,
  setShow,
  asset,
  index,
  view = 'grid',
  onContextSelect,
}: {
  asset: any;
  setCurrentData: any;
  setShow: any;
  index: number;
  view?: 'grid' | 'list';
  onContextSelect?: (context: any) => void;
}) => {
  const imageName = truncateText(asset?.name, 20) || 'Untitled';
  const router = useRouter();
  const pathname = usePathname();
  const isListView = view === 'list';
  const viewAsset = useCallback(() => {
    if (setCurrentData) setCurrentData(asset);
    setShow(true);

    router.replace(
      queryString.stringifyUrl({
        url: pathname!,
        query: {
          file: asset.id,
        },
      }),
      { scroll: false }
    );
  }, [asset, setCurrentData, setShow, pathname]);

  const Components = {
    image: Img,
    video: 'video',
  } as Record<string, any>;
  const fileType = asset?.type.split('/')[0];
  const Component = Components[fileType] || Img;

  const hasContext = typeof onContextSelect !== 'undefined' && isListView;
  return (
    // <Draggable draggableId={asset.id} index={index} disabled={true}>
    //   {(provided: any, snapshot: any) => (
    <div
      // ref={provided.innerRef}
      // {...provided.draggableProps}
      // {...provided.dragHandleProps}
      className={classNames([
        styles.Generation,
        styles[view],
        // { [styles.isDragging]: snapshot.isDragging },
      ])}
    >
      <Flex.Column
        gap={8}
        className="h-full"
        direction={isListView ? 'row' : undefined}
      >
        <button
          type="button"
          onClick={viewAsset}
          className={isListView ? 'basis-[40px] shrink-0' : 'h-full'}
        >
          <div
            className={classNames([styles.MetaImage])}
            style={
              {
                ['--img-bg']: `url(${asset?.url})`,
              } as React.CSSProperties
            }
          >
            <Component
              src={asset?.url}
              alt={`generation ${asset?.id}`}
              className="h-full"
            />

            {asset?.caption && (
              <div className={styles.Generations__Meta}>
                <Text weight={600} noOfLines={2} fontSize="var(--font-accent)">
                  {asset.caption}
                </Text>
              </div>
            )}
          </div>
        </button>
        <Flex.Column
          gap={10}
          className="px-2 py-1"
          direction={isListView ? 'row' : undefined}
          fullWidth
          justifyContent={isListView ? 'space-between' : undefined}
        >
          <Flex alignItems="center" gap={6} className="flex-1">
            {asset?.type.split('/')[0] === 'image' ? (
              <AppIcon type="assets" />
            ) : (
              <PlayIcon color="var(--gray)" width="18" height="18" />
            )}
            <Text weight={600} noOfLines={1} className="flex-1">
              {imageName}
            </Text>
            {!isListView && (
              <AssetActions asset={asset} onViewAsset={viewAsset} />
            )}
          </Flex>
          <Flex
            gap={8}
            className={styles.Generations__Meta}
            justifyContent="space-between"
            alignItems="center"
            css={
              isListView
                ? {
                    flex: '0 1 35%',
                  }
                : undefined
            }
          >
            {hasContext && (
              <CallToAction.button
                size="xs"
                onClick={() => onContextSelect(asset)}
                className={classNames([
                  'whitespace-pre',
                  styles.Generations__Context,
                ])}
              >
                <Text weight={600} fontSize="var(--font-caption)">
                  Use file
                </Text>
              </CallToAction.button>
            )}

            <Flex gap={4} alignItems="center">
              <Avatar
                src={asset?.creator?.photo}
                alt={extractFirstName(asset?.creator?.name)}
                size={24}
              />

              <Text weight={600} noOfLines={isListView ? 1 : undefined}>
                {truncateText(extractFirstName(asset?.creator?.name)!, 30)}
              </Text>
            </Flex>

            <Text
              fontSize="var(--font-accent)"
              weight={600}
              className="opacity-70 whitespace-pre"
              noOfLines={isListView ? undefined : 1}
            >
              <>
                {!isListView ? (
                  <>
                    {formatDistanceToNow(new Date(asset?.updatedAt))} {' ago'}
                  </>
                ) : (
                  format(new Date(asset?.updatedAt), "MMM d, yyyy 'at' h:mm a")
                )}
              </>
            </Text>
            {isListView && (
              <AssetActions asset={asset} onViewAsset={viewAsset} />
            )}
          </Flex>
        </Flex.Column>
      </Flex.Column>
    </div>
    //   )}
    // </Draggable>
  );
};

export const AssetActions = ({
  asset,
  onViewAsset,
}: {
  asset: any;
  onViewAsset?: () => void;
}) => {
  const { createDisclosure, createToast } = useFeedback();
  const { mutateAsync: deleteFile } = useDeleteUserFileMutation({
    onError: (error: any) => {
      createToast({
        message: error?.message || 'An error occurred',
        variant: 'error',
      });
    },
    onSuccess: () => {
      createToast({
        message: 'File deleted successfully',
      });
    },
  });
  const [show, setShow] = useState(false);
  return (
    <>
      <Popover size="sm" style={{ flex: '0 0 16px' }}>
        <Popover.Trigger>
          <button>
            <img
              src={getDataIcons('more', '#000')}
              className={classNames([
                'h-4 w-4 object-contain',
                styles.GenerationActions,
              ])}
            />
          </button>
        </Popover.Trigger>
        <Popover.Content isDropdown>
          {typeof onViewAsset === 'function' && (
            <button onClick={onViewAsset}>View</button>
          )}
          <button
            onClick={() => {
              setShow(true);
            }}
          >
            Move to folder
          </button>
          <button
            onClick={async () => {
              await createDisclosure({
                title: 'Delete File',
                message: 'Are you sure you want to delete this file?',
                confirmText: 'Delete',
              });

              deleteFile({
                id: asset.id,
              });
            }}
          >
            Delete
          </button>
        </Popover.Content>
      </Popover>
      <CreateFolderModal
        show={show}
        onClose={() => setShow(false)}
        data={asset}
      />
    </>
  );
};

interface IAssetCardVirtualized {
  data: any[];
  setCurrentData: (data: any) => void;
  setShow: (show: boolean) => void;
  view?: 'grid' | 'list';
  limit?: number;
  onContextSelect?: (context: any) => void;
}

// export const AssetCardVirtualized: React.FC<IAssetCardVirtualized> = ({
//   data,
//   setCurrentData,
//   setShow,
//   view = 'list',
//   limit = 20,
//   onContextSelect,
// }) => {
//   const parentRef = useRef<HTMLDivElement>(null);

//   const limitedData = useMemo(() => {
//     return data?.slice(0, limit || data?.length) || [];
//   }, [data, limit]);

//   const virtualizer = useVirtualizer({
//     count: limitedData.length,
//     getScrollElement: () => parentRef.current,
//     estimateSize: () => (view === 'list' ? 80 : 280), // Adjust height based on view
//     overscan: 5,
//     horizontal: false,
//   });

//   if (!limitedData?.length) return null;

//   return (
//     <div
//       ref={parentRef}
//       className={classNames([
//         styles.AssetGrid,
//         styles[view],
//         styles['virtual-scroll-container'],
//       ])}
//       style={{
//         height: '100%',
//         maxHeight: '800px',
//         overflow: 'auto',
//       }}
//     >
//       <div
//         style={{
//           position: 'relative',
//           height: `${virtualizer.getTotalSize()}px`,
//         }}
//       >
//         {virtualizer.getVirtualItems().map((virtualRow) => {
//           const asset = limitedData[virtualRow.index];
//           return (
//             <div
//               key={virtualRow.key}
//               data-index={virtualRow.index}
//               ref={virtualizer.measureElement}
//               style={{
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 width: '100%',
//                 transform: `translateY(${virtualRow.start}px)`,
//               }}
//             >
//               <AssetCard
//                 asset={asset}
//                 setCurrentData={setCurrentData}
//                 setShow={setShow}
//                 index={virtualRow.index}
//                 view={view}
//                 onContextSelect={onContextSelect}
//               />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

export const AssetCardContainer: React.FC<IAssetCardVirtualized> = ({
  data,
  view = 'grid',
  ...rest
}) => {
  // if (view === 'list') {
  //   return <AssetCardVirtualized data={data} view={view} {...rest} />;
  // }

  return (
    <>
      {data?.map((asset, index) => (
        <AssetCard
          key={asset.id}
          asset={asset}
          index={index}
          view={view}
          {...rest}
        />
      ))}
    </>
  );
};

export default AssetCardContainer;
