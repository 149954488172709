import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from './config';

interface StreamResponse {
  content: string;
}

interface StreamCallbacks {
  onData: (data: StreamResponse) => void;
  onError: (error?: Error) => void;
}

export type BaseQueryFnType = <
  ResultType,
  ErrorType,
  QueryFnType extends (...args: any[]) => any,
>(
  queryFn: QueryFnType
) => Promise<{ data?: ResultType; error?: ErrorType }>;

/**
 * A base query function for `react-query` that uses `axios` to make requests.
 * @param baseUrl The base URL to use for all requests.
 * @returns A base query function for `react-query`.
 *
 * @example
 * ```ts
 * import { useQuery } from 'react-query';
 * import { axiosBaseQuery } from './blackAxios';
 *
 * const baseQueryFn = axiosBaseQuery();
 *
 * function useUserQuery() {
 *  return useQuery('user', () => baseQueryFn({ url: '/user' }));
 * }
 */
export const axiosBaseQuery = <ResultType, ErrorType>(
  baseUrl = API_URL
): BaseQueryFnType =>
  async function axiosBaseQuery<
    ResultType,
    ErrorType,
    QueryFnType extends AxiosRequestConfig & { body?: any; token?: string },
  >(queryFn: QueryFnType): Promise<{ data?: ResultType; error?: ErrorType }> {
    try {
      const {
        url: pathOrURL,
        method,
        data,
        body,
        params,
        headers,
        token: __,
      } = queryFn as AxiosRequestConfig & { body?: any; token?: string };
      const token = __ ?? Cookies?.get('__session');
      const url = getRequestURL(pathOrURL!, baseUrl);

      const API_CONFIG = {
        url,
        method,
        data: body || data,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
          ...(token && {
            Authorization: `Bearer ${token}`,
          }),
        },
      };

      const result = await axios({
        ...API_CONFIG,
        params,
      });

      return result.data;
    } catch (axiosError) {
      const err = axiosError as AxiosError<ErrorType>;
      throw err?.response?.data || err;
    }
  };

export const baseQueryFn = axiosBaseQuery(API_URL) as BaseQueryFnType;

export function getRequestURL(pathOrURL: string, baseURL: string) {
  const isExternalURL = /(http|https):\/\//i.test(pathOrURL);
  if (isExternalURL) return pathOrURL;

  const path = pathOrURL.startsWith('/v2/') ? pathOrURL : '/v1' + pathOrURL;
  const _baseURL = baseURL.replace('/v1', '');

  const url = new URL(path, _baseURL);

  return url.toString();
}

export const getBearerToken = () => `Bearer ${Cookies.get('__session')}`;
