import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Avatar,
  CallToAction,
  Flex,
  Heading,
  Img,
  Modal,
  Popover,
  Text,
  camelCaseToCleanString,
  cleanCompositions,
  extractImage,
  getDataIcons,
  pluralize,
} from '@nex/labs';
import Bookmark from '@nex/icons/svg/misc/bookmark.svg';
import Link from 'next/link';
import Router from 'next/router';
import classNames from 'classnames';
import { Composition, Image, type Blocks } from '@nex/types/lib/artboard';
import { motion } from 'framer-motion';
import ReadMore from '@/components/misc/read-more';

import Clock from '@nex/icons/svg/misc/clock.svg';
import Reuse from '@nex/icons/svg/blocks/reload.svg';

import posthog from 'posthog-js';

import { CreateFolderModal, useArtboardQueryData } from '@/features/console';
import { formatDistanceToNow } from 'date-fns';

import { useArtboardStore } from '@/state/useStore';

import {
  ASSETS_CONSTANTS,
  aspectMap,
  aspectRatioMap,
} from '../../../utils/constants';

import styles from './generation-info.module.scss';
import { UseAsControl } from '@/components/misc/use-control';
import { AppIcon } from '@/components/misc/app-icon';
import { IconMap } from '@/components/misc/controls-icon';
import { Accordion } from '@/components/accordion';
import { Vote } from '@/components/misc/vote';
const ITEMS_PER_PAGE = 10;

export const GenerationPaneModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const { currentGeneration: _curr, setCurrentGeneration } = useArtboardStore();
  const { artboard } = useArtboardQueryData();
  const allGenerations = cleanCompositions(artboard?.compositions) ?? [];

  const currentData = useMemo(() => {
    return _curr;
  }, [_curr]);

  useEffect(() => {
    posthog.capture('viewed_artboard_generation', {
      generation_id: _curr?.id,
    });
  }, [_curr?.id]);

  const currParent = useMemo(() => {
    return currentData?.parent ?? {};
  }, [currentData?.parent]);

  return (
    <Modal in={show} onClose={onClose} size={'xxlg'} title={undefined}>
      <GenerationInfo
        currParent={currParent}
        currentData={currentData}
        onClose={onClose}
        allGenerations={allGenerations}
        setCurrentGeneration={setCurrentGeneration}
      />
    </Modal>
  );
};

export const GenerationInfo = memo(
  ({
    currParent,
    currentData,
    allGenerations = [],
    onClose,
    setCurrentGeneration,
  }: {
    currParent: Composition;
    currentData: Image;
    onClose: () => void;
    setCurrentGeneration: (data: Blocks) => void;
    allGenerations?: Composition[];
  }) => {
    const { setBlock, reuseBlock, defaultConfig, setActiveTab } =
      useArtboardStore();
    const [current, setCurrent] = React.useState<number | null>(0);
    const parentIndex = Math.max(
      allGenerations?.findIndex((gen) => gen.id === currParent?.id),
      0
    );

    const [visibleGenerations, setVisibleGenerations] = useState<Composition[]>(
      []
    );
    const [currentPage, setCurrentPage] = useState(1);

    const generationRef = React.useRef(null);

    useEffect(() => {
      const startIndex = Math.max(0, parentIndex - ITEMS_PER_PAGE / 2);
      const endIndex = startIndex + ITEMS_PER_PAGE;
      setVisibleGenerations(allGenerations.slice(startIndex, endIndex));
      setCurrentPage(Math.floor(startIndex / ITEMS_PER_PAGE) + 1);
    }, [allGenerations, parentIndex]);

    const loadMoreGenerations = useCallback(() => {
      const startIndex = currentPage * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      const newGenerations = allGenerations.slice(startIndex, endIndex);
      setVisibleGenerations((prev) => [...prev, ...newGenerations]);
      setCurrentPage((prev) => prev + 1);
    }, [allGenerations, currentPage]);

    const parent = useMemo(() => {
      if (!allGenerations?.length) return currParent?.images;
      const _curr = allGenerations?.find((gen) => gen.id === currParent?.id);
      return _curr?.images;
    }, [allGenerations, currParent]);

    const generation = parent?.[current ?? 0];

    const move = useCallback(
      (direction: 'left' | 'right') => {
        if (direction === 'left') {
          const allGen = allGenerations[parentIndex - 1]
            ?.images as unknown as Composition[];
          if (current! > 0) {
            setCurrent((prev) => Math.max(prev! - 1, 0));
          } else if (parentIndex >= 0) {
            const lastItem = allGen?.length - 1;

            if (allGenerations[parentIndex - 1]) {
              setCurrentGeneration({
                ...allGen[lastItem],
                parent: allGenerations[parentIndex - 1],
              } as any);
            }

            if (lastItem) setCurrent(lastItem);
          }
        }

        if (direction === 'right') {
          const allGen = allGenerations[parentIndex + 1]
            ?.images as unknown as Composition[];
          if (!current || current < parent?.length! - 1) {
            setCurrent((prev) => Math.min(prev! + 1, parent?.length! - 1));
          } else if (parentIndex <= allGenerations.length - 1) {
            if (allGenerations?.[parentIndex + 1]) {
              return setCurrentGeneration({
                ...allGen[0],
                parent: allGenerations[parentIndex + 1],
              } as any);
            }

            setCurrent(0);
            setCurrentGeneration({
              ...(allGenerations as any)[0].images[0],
              parent: allGenerations[0],
            } as any);
          }
        }
      },
      [
        setCurrent,
        parent,
        allGenerations,
        parentIndex,
        setCurrentGeneration,
        current,
      ]
    );

    useEffect(() => {
      // use arrow left and right to navigate
      const handleKeyPress = (e: any) => {
        if (e.key === 'ArrowLeft') {
          move('left');
        }

        if (e.key === 'ArrowRight') {
          move('right');
        }
      };

      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [parent, move]);

    useEffect(() => {
      setCurrent(parent?.findIndex((d: any) => d.id === currentData?.id)!);
      const genRef = generationRef.current as any;
      if (genRef) {
        const allGenerations = genRef.querySelectorAll(
          'button[data-generation-active=true]'
        );

        if (allGenerations[0]) {
          genRef.scrollTo({
            behavior: 'smooth',
            top: allGenerations[0].offsetTop - 24,
          });
        }
      }
    }, [parent, currentData?.id]);

    const extractSecondaryProperties = {
      size: 'Number of Generations',
      aspectRatio: 'Size',
      raw: 'Raw Mode',
    };

    const extractPrimaryProperties = {
      ...Object.keys(currParent?.blocks || {}).reduce((acc, key) => {
        if ((['preset', 'prompt', 'model'] as string[]).includes(key))
          return acc;

        return {
          ...acc,
          [`blocks.${key}`]: key,
        };
      }, {}),
    };

    function extractData(
      properties: Record<string, string> = {},
      data = {
        ...currParent,
        ...currentData,
      }
    ) {
      const result: Record<string, any> = {};
      Object.entries(properties).forEach(([propertyPath, newKey]) => {
        const pathSegments = propertyPath.split('.');
        let tempData = data as unknown as Record<string, any> | null;

        for (const segment of pathSegments) {
          if (tempData?.[segment] !== undefined) {
            tempData = tempData[segment];
          } else {
            tempData = null;
            break;
          }
        }

        if (tempData) {
          result[newKey as keyof typeof result] = tempData;
        }
      });

      return result;
    }

    const [collectionOpen, setCollectionOpen] = React.useState(false);

    const generationActions = {
      Canvas: [
        {
          action: `/canvas?imageKey=${encodeURIComponent(
            generation?.key!
          )}&imageOptions=${encodeURIComponent(
            `${defaultConfig.resolution}x${aspectMap[defaultConfig.resolution as keyof typeof aspectMap]}`
          )}`,
          text: 'Edit',
        },
      ],
      Studio: [
        {
          action: () => {
            reuseBlock(currParent);
            onClose();
          },
          text: 'Reuse Prompts',
        },
        {
          component: (
            <Popover size="sm">
              <Popover.Trigger>
                <CallToAction.button
                  size="xs"
                  outline
                  variant="clear"
                  className="text-[13px]"
                >
                  Use as Control
                </CallToAction.button>
              </Popover.Trigger>
              <Popover.Content isDropdown>
                <Text align="left" fontSize="13px" color="#000">
                  Use this image as
                </Text>

                <UseAsControl
                  imageURL={extractImage(generation)}
                  imageKey={generation?.key!}
                  onSelect={() => {
                    onClose();
                  }}
                />
              </Popover.Content>
            </Popover>
          ),
        },
      ],
      Enhancer: [
        {
          action: `/enhance?img=${encodeURIComponent(
            extractImage(generation)
          )}`,
          text: 'Enhance',
        },
      ],
      Animator: [
        {
          action: `/animator?img=${encodeURIComponent(
            extractImage(generation)
          )}&imageKey=${encodeURIComponent(generation?.key!)}`,
          text: 'Make Video',
        },
      ],
    };

    return (
      <Flex gap={24} flexWrap="wrap">
        <Flex.Row gap={14} className={styles.GenerationImages}>
          <div className={styles.GenerationActionPreviewBar}>
            <Flex.Column
              flexWrap="wrap"
              gap={8}
              alignItems="center"
              scrollable
              className="max-h-[80%] max-w-[100%] m-auto relative z-10 lg:max-h-[80vh]"
              justifyContent="flex-start"
              ref={generationRef}
            >
              {(visibleGenerations.length
                ? visibleGenerations
                : [{ ...currParent }]
              ).map((generation: any) => (
                <Fragment key={generation?.id}>
                  {generation?.images?.map((gen: any) => (
                    <button
                      key={gen?.id}
                      onClick={() => {
                        if (setCurrentGeneration) {
                          return setCurrentGeneration({
                            ...gen,
                            parent: generation,
                          });
                        }

                        setCurrent(
                          generation?.images?.findIndex(
                            (d: any) => d.id === gen?.id
                          )
                        );
                      }}
                      data-generation-active={generation?.id === currParent?.id}
                      className={classNames([
                        styles.ResultsImageSlateButton,
                        generation?.id === currParent?.id && styles.active,
                        'w-[40px] h-[40px] flex-none',
                      ])}
                    >
                      <Img
                        style={{
                          border:
                            gen?.key === parent?.[current as any]?.key
                              ? '2px solid var(--primary-theme)'
                              : '',
                        }}
                        src={extractImage(gen)}
                        alt={gen?.caption}
                        className="w-full h-full rounded-[6px] object-cover"
                      />
                    </button>
                  ))}
                </Fragment>
              ))}
            </Flex.Column>
          </div>
          <div className="relative m-auto w-full max-w-[90%]">
            <motion.div
              key={current}
              initial={{ opacity: 0, x: -4 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 4 }}
              className={styles.GenerationImage}
            >
              <Img
                src={extractImage(generation)}
                alt="generation"
                className="w-full h-full max-h-[800px] max-w-[100%] object-contain mx-auto rounded-md"
              />
              <div className={styles.GenerationImageActions}>
                <Vote data={currentData} />
                <Flex gap={8}>
                  <CallToAction.button
                    size="xs"
                    variant="clear"
                    download={extractImage(generation)}
                  >
                    Download
                  </CallToAction.button>

                  <CallToAction.button
                    size="xs"
                    variant="clear"
                    onClick={() => {
                      setCollectionOpen(true);
                    }}
                  >
                    <Bookmark width={18} />
                  </CallToAction.button>
                </Flex>
              </div>
            </motion.div>
          </div>
        </Flex.Row>

        <>
          <Flex.Column gap={24} className="mt-0 min-h-[450px] flex-1">
            <Flex.Column>
              <Flex gap={8} alignItems="center">
                <Avatar
                  src={currParent?.creator?.photo}
                  alt={currParent?.creator?.name}
                  size={30}
                />

                <Text weight={700}>{currParent?.creator?.name}</Text>
              </Flex>
            </Flex.Column>

            <Flex flexWrap="wrap" gap={12}>
              <Flex.Column gap={4} className="basis-[calc(100%/3.5)]">
                <Text weight={800} casing="capitalize">
                  Created In
                </Text>
                <Flex gap={4} alignItems="center">
                  <AppIcon
                    type="studio"
                    color="#000"
                    width={20}
                    height="auto"
                  />{' '}
                  <Text weight={500} casing="capitalize">
                    Studio
                  </Text>
                </Flex>
              </Flex.Column>

              <Flex.Column gap={4} className="basis-[calc(100%/3.5)]">
                <Text weight={800} casing="capitalize">
                  Model
                </Text>
                <Text weight={500} casing="capitalize">
                  {currParent?.model?.name}
                </Text>
              </Flex.Column>
              <RenderIf condition={!!currParent?.blocks?.preset?.name}>
                <Flex.Column gap={4} className="basis-[calc(100%/3.5)]">
                  <Text weight={800} casing="capitalize">
                    Style
                  </Text>
                  <Text weight={500} casing="capitalize" noOfLines={1}>
                    {currParent.blocks?.preset?.name}
                  </Text>
                </Flex.Column>
              </RenderIf>
            </Flex>
            <RenderIf condition={!!currParent?.blocks?.prompt}>
              <Flex flexWrap="wrap" gap={12}>
                <Flex.Column gap={4}>
                  <Text weight={800} casing="capitalize">
                    Prompt Used:
                  </Text>
                  <ReadMore
                    text={`${currParent?.blocks?.prompt}`}
                    limit={100}
                  />
                </Flex.Column>
              </Flex>
            </RenderIf>
            <RenderIf
              condition={
                !!Object.entries(extractData(extractPrimaryProperties))?.length
              }
            >
              <Flex.Column gap={4} className="basis-[47%]">
                <Text weight={800} casing="capitalize">
                  Controls Used:
                </Text>
                <Flex flexWrap="wrap" gap={12}>
                  {Object.entries(extractData(extractPrimaryProperties)).map(
                    ([key, value]: [any, any], index) => {
                      return (
                        <Flex.Column gap={4} key={index}>
                          {typeof value === 'object' ? (
                            <>
                              {Object.keys(value as any).map(
                                (_value: any, index: number) => {
                                  const hasImage =
                                    typeof value[_value] === 'string' &&
                                    value[_value]?.startsWith('http');
                                  const Icon =
                                    IconMap[key as keyof typeof IconMap] ||
                                    null;
                                  if (['key', 'type'].includes(_value))
                                    return null;

                                  if (_value === 'id') {
                                    return (
                                      <Link
                                        onClick={() => {
                                          setActiveTab('sketch');
                                        }}
                                        href={`${Router.asPath}?sketchId=${value[_value]}`}
                                        key={index}
                                      >
                                        <Text
                                          weight={700}
                                          color="var(--primary-theme)"
                                          casing="capitalize"
                                          decoration="underline"
                                        >
                                          View Sketch
                                        </Text>
                                      </Link>
                                    );
                                  }

                                  return (
                                    <Flex
                                      flexWrap="wrap"
                                      gap={10}
                                      alignItems="center"
                                      className={`mt-2
                                    ${
                                      hasImage
                                        ? 'cursor-pointer bg-[var(--primary-gray)] rounded-md p-1 w-fit'
                                        : 'cursor-text'
                                    }
                                `}
                                      key={index}
                                    >
                                      {hasImage ? (
                                        <a
                                          href={value[_value]}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="relative"
                                        >
                                          <Flex.Column
                                            alignItems="center"
                                            justifyContent="center"
                                            className="absolute h-full w-full top-[50%] left-[50%] z-10 bg-[#0000004a] text-white "
                                            css={{
                                              transform:
                                                'translate(-50%, -50%)',
                                            }}
                                            gap={2}
                                          >
                                            <Icon width="auto" height={20} />
                                            <Text
                                              weight={700}
                                              casing="capitalize"
                                              color="#fff"
                                              fontSize="var(--font-caption)"
                                            >
                                              {camelCaseToCleanString(
                                                key
                                                  ?.replace(/[^a-zA-Z ]/g, ' ')
                                                  .replace('softedge', 'Sketch')
                                                  .replace('Image', '')
                                              )}
                                            </Text>
                                          </Flex.Column>
                                          <img
                                            src={value[_value]}
                                            alt="image"
                                            key={index}
                                            className="w-[80px] h-[80px] object-cover rounded-md"
                                          />
                                        </a>
                                      ) : null}
                                    </Flex>
                                  );
                                }
                              )}
                            </>
                          ) : null}
                        </Flex.Column>
                      );
                    }
                  )}
                  <Flex.Column gap={4} className="basis-[100%] mt-4" fullWidth>
                    <Accordion title="Advanced Metadata" size="sm" bare>
                      <Flex flexWrap="wrap" gap={12}>
                        {Object.entries(
                          extractData(extractSecondaryProperties, {
                            ...currParent.metadata,
                          } as any)
                        ).map(([key, value], index) => (
                          <Flex.Column
                            gap={4}
                            key={`${index}:${key}`}
                            className="basis-[47%]"
                          >
                            <Text weight={800}>{key}: </Text>

                            {Array.isArray(value) ? (
                              <Text fontSize="14px" className="opacity-80">
                                {(aspectRatioMap as any)?.[value.join('x')] ||
                                  value.join(', ')}
                              </Text>
                            ) : ['object', 'boolean'].includes(typeof value) ? (
                              <Text>{JSON.stringify(value)}</Text>
                            ) : (
                              <ReadMore text={value as string} limit={100} />
                            )}
                          </Flex.Column>
                        ))}
                      </Flex>
                    </Accordion>
                  </Flex.Column>
                </Flex>
              </Flex.Column>
            </RenderIf>
            <Flex.Column className="mt-auto relative" gap={12}>
              <Text weight={800}>Use this Image In</Text>
              <Flex.Column gap={8}>
                {Object.entries(generationActions).map(([key, actions]) => (
                  <Flex key={key} gap={12}>
                    <Flex alignItems="center" gap={8}>
                      <AppIcon
                        type={key.toLowerCase() as any}
                        color="#000"
                        width={20}
                        height="auto"
                      />
                      <Text weight={600} casing="capitalize">
                        {key}
                      </Text>
                    </Flex>
                    <Flex gap={2} flexWrap="wrap" alignItems="center">
                      {actions.map((action: any, index: number) => {
                        const CallToActionComp =
                          typeof action.action === 'string'
                            ? CallToAction.a
                            : CallToAction.button;

                        if (action.component) return action.component;
                        return (
                          <CallToActionComp
                            key={index}
                            size="xs"
                            {...(typeof action.action === 'string'
                              ? {
                                  href: action.action,
                                }
                              : {
                                  onClick: action.action,
                                })}
                            variant="secondary"
                            className="text-[13px]"
                          >
                            {action.text}
                          </CallToActionComp>
                        );
                      })}
                    </Flex>
                  </Flex>
                ))}
              </Flex.Column>
            </Flex.Column>
          </Flex.Column>
          <CreateFolderModal
            data={currentData}
            show={collectionOpen}
            onClose={() => {
              setCollectionOpen(false);
            }}
          />
        </>
      </Flex>
    );
  }
);

const RenderIf = ({
  condition,
  children,
}: {
  condition: boolean;
  children: any;
}) => {
  return condition ? children : null;
};

GenerationInfo.displayName = 'GenerationInfo';
