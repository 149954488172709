import classNames from 'classnames';
import React, { Fragment, useEffect, useMemo } from 'react';

import {
  Avatar,
  CallToAction,
  cleanCompositions,
  Flex,
  Img,
  Text,
  getDataIcons,
  truncateText,
  useMediaQuery,
  useFeedback,
  Heading,
} from '@nex/labs';

import TextIcon from '@nex/icons/svg/blocks/action-bar/text.svg';
import ColorIcon from '@nex/icons/svg/blocks/action-bar/color.svg';
import PoseIcon from '@nex/icons/svg/blocks/action-bar/pose.svg';
import LineConnectors from '@nex/icons/svg/misc/line-connectors.svg';
import { useUserStore, useArtboardStore } from '@/state/useStore';

import { useGetArtboardQuery } from '@/state/query/block';
import { useRouter } from 'next/router';

import { formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import { GenerationPaneModal } from '../modals/generation-info';
import { Composition } from '../composition';

import FetchContainer from '@/components/misc/fetch-container';
import { RANDOM_PROMPTS } from '@lib/constants';

import styles from './generations.module.scss';
import { useArtboardQueryData } from '../../artboard';

export const ArtboardContent = ({ showHistory }: { showHistory?: boolean }) => {
  const router = useRouter();
  const { profile } = useUserStore();
  const { createToast } = useFeedback();

  const {
    setBlock,
    requests,
    currentGeneration,
    setCurrentGeneration,
    isGenerating,
    resetArtboard,
  } = useArtboardStore();

  const isInArtboard = useMemo(
    () => !!router.query.id && router?.query?.id !== 'new',
    [router.query.id]
  );

  const {
    data: artboardData,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useGetArtboardQuery(
    {
      artboardId: router.query.id as string,
      workspaceId: profile?.organizationId,
      limit: 20,
    },
    {
      enabled: isInArtboard,
      onError: (error: any) => {
        if (error?.statusCode === 403) {
          router.push('/artboard/new');
          createToast({
            message:
              "You don't have permission to view this artboard, Redirecting to create a new artboard",
            variant: 'error',
          });
        }
      },
    }
  );

  const { artboard } = useArtboardQueryData();
  const hasCompositions = useMemo(
    () =>
      !artboardData?.artboard?.compositions?.length &&
      !isGenerating &&
      (!isFetching || !isLoading),
    [
      artboardData?.artboard?.compositions?.length,
      isFetching,
      isGenerating,
      isLoading,
    ]
  );

  const randomPrompt = Math.floor(Math.random() * RANDOM_PROMPTS.length);
  useEffect(() => {
    //TODO: optimize this call by create a response queue
    if (!isInArtboard) {
      resetArtboard();
      return;
    }
    refetch();
  }, [requests.length, isInArtboard]);

  return (
    <FetchContainer
      isLoading={isInArtboard && isLoading}
      hasMore={hasNextPage}
      fetchNextPage={() => {
        fetchNextPage();
      }}
    >
      {artboardData?.error && (
        <Flex.Column
          gap={8}
          className="bg-[var(--primary-white)] px-4 py-3 rounded-md m-2 mt-[64px]"
        >
          <Text weight={500} className="-mb-1 opacity-70">
            {'Upgrading your artboard, please wait...'}
          </Text>
          <Text fontSize="16px" weight={700} className="mb-2">
            {artboardData?.error?.message ||
              'We are working on fixing this artboard generations'}
          </Text>
          <CallToAction.button size="sm" onClick={() => refetch()}>
            Refresh
          </CallToAction.button>
        </Flex.Column>
      )}
      {hasCompositions ? (
        <div className={classNames([styles.EmptyStateBlocks, styles.full])}>
          <Flex.Column
            gap={8}
            alignItems="center"
            justifyContent="space-between"
            className="h-full pb-[48px]"
          >
            <Heading.h4>Discover the power of control blocks</Heading.h4>
            <Flex.Column gap={40} alignItems="center">
              <img src="/images/dashboard/image-controls.png" />
              <div className={styles.EmptyStateBlocksContent}>
                <LineConnectors
                  className={styles.EmptyStateBlocksContentConnector}
                />
                <div
                  className={styles.EmptyStateBlocksContentTile}
                  style={{ marginLeft: '-32px' }}
                >
                  <Flex.Row gap={8} alignItems="center">
                    <TextIcon color="var(--text-black)" />
                    <Text weight={700}>Text Prompt</Text>
                  </Flex.Row>

                  <Text color="var(--text-secondary)" className="opacity-70">
                    a cinematic photograph of a cyberpunk ceramic texture robot,
                    shield face
                  </Text>
                </div>
                <div className={styles.EmptyStateBlocksContentTile}>
                  <Flex.Row gap={8} alignItems="center">
                    <ColorIcon color="var(--text-black)" />
                    <Text weight={700}>Color Control</Text>
                  </Flex.Row>
                  <img src="/images/dashboard/default-color.png" />
                </div>
                <div className={styles.EmptyStateBlocksContentTile}>
                  <Flex.Row gap={8} alignItems="center">
                    <PoseIcon color="var(--text-black)" />
                    <Text weight={700}>Pose Control</Text>
                  </Flex.Row>
                  <img src="/images/dashboard/default-pose.png" />
                </div>
              </div>
            </Flex.Column>
            <CallToAction.button
              size="md"
              variant="secondary"
              onClick={async () => {
                setBlock('prompt', RANDOM_PROMPTS[randomPrompt]);
              }}
              leadingIcon={<img src={getDataIcons('add', '#000')} />}
            >
              Try a random prompt
            </CallToAction.button>
          </Flex.Column>
        </div>
      ) : null}

      {showHistory ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          key={showHistory ? 'history' : 'generations'}
          transition={{ duration: 0.2 }}
          className={styles.BlockPaneHistory}
        >
          {artboard?.compositions?.length
            ? cleanCompositions(artboard?.compositions)?.map(
                (composition: any) => (
                  <Flex.Column
                    className={styles.BlockPaneGeneration}
                    key={`${composition.id}:grp_artboard`}
                    gap={8}
                  >
                    {composition?.images
                      ?.filter((_: any, i: number) => i === 0)
                      ?.map((image: any, i: number) => (
                        <div
                          key={`${image?.id}:img_action:${composition?.id}`}
                          className={styles.BlockPaneGenerationImageActions}
                          role="button"
                          onKeyDown={(e: any) => {
                            if (e.target.tagName !== 'IMG') return;
                            setCurrentGeneration({
                              ...composition.images[0],
                              parent: composition,
                            });
                          }}
                          onMouseDown={(e: any) => {
                            if (e.target.tagName !== 'IMG') return;
                            setCurrentGeneration({
                              ...composition.images[0],
                              parent: composition,
                            });
                          }}
                          tabIndex={0}
                        >
                          {i === 0 && composition?.creator?.id ? (
                            <Flex
                              alignItems="center"
                              className={styles.BlockUser}
                            >
                              <Avatar
                                src={composition?.creator?.photo}
                                alt={composition?.creator?.name}
                                size={20}
                                style={{
                                  borderRadius: '50%',
                                }}
                              />{' '}
                              <Text fontSize="12px" noOfLines={1}>
                                {truncateText(
                                  composition?.creator?.name,
                                  30,
                                  false
                                )}
                              </Text>
                            </Flex>
                          ) : null}

                          <Img
                            src={image.url}
                            alt={'generation image'}
                            className={styles.GenerationImg}
                          />
                        </div>
                      ))}
                    <Text>
                      {formatDistance(
                        new Date(),
                        new Date(composition.updatedAt),
                        {}
                      )}{' '}
                      ago
                    </Text>
                  </Flex.Column>
                )
              )
            : null}
        </motion.div>
      ) : (
        <motion.div
          className={styles.BlockPaneGenerations}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          key={showHistory ? 'history' : 'generations'}
          transition={{ duration: 0.2 }}
        >
          {requests?.map((val: any, i: number) => (
            <ContentLoading
              isLoading={isFetching && isLoading}
              key={`requests`}
            />
          ))}
          {artboard?.compositions?.length
            ? artboard?.compositions?.map((composition: any) => (
                <Composition composition={composition} key={composition.id} />
              ))
            : null}
        </motion.div>
      )}
      <GenerationPaneModal
        show={!!currentGeneration}
        onClose={() => setCurrentGeneration(null)}
      />
    </FetchContainer>
  );
};

export const ContentLoading = ({ isLoading }: { isLoading: boolean }) => {
  const { defaultConfig, requests } = useArtboardStore();

  const [_, height] = defaultConfig?.resolution?.split('x');

  if (requests?.length === 0 && !isLoading) return null;
  return (
    <>
      {[Array.from({ length: defaultConfig?.size || 4 })]?.map(
        (val: any, i) => (
          <Fragment key={`${i + 1}:requests`}>
            <div className="block">
              <div className={`${styles.BlockPaneGeneration}`}>
                {val?.user?.id && (
                  <Flex
                    gap={4}
                    alignItems="center"
                    className={styles.BlockUser}
                  >
                    <Avatar
                      src={val?.user?.photo}
                      alt={val?.user?.name}
                      size={20}
                    />{' '}
                    <Text fontSize="12px" noOfLines={1}>
                      {val?.user?.name}
                    </Text>
                  </Flex>
                )}
                <Flex
                  gap={8}
                  alignItems="center"
                  className={styles.BlockPaneGenerationImages}
                >
                  {Array.from({ length: defaultConfig?.size || 4 }).map(
                    (_, i) => (
                      <div
                        className={styles.BlockPaneGenerationImageActions}
                        key={`${i}:gen_img_loading`}
                      >
                        <ContentLoader
                          style={{
                            height: height ? `${+height / 2.4}px` : 'auto',
                          }}
                          className={styles.BlockPaneGenerationImageLoading}
                        />
                      </div>
                    )
                  )}
                </Flex>
              </div>
            </div>
          </Fragment>
        )
      )}
    </>
  );
};

export const ContentLoader = ({
  style,
  className,
}: {
  style?: Record<string, string>;
  className?: string;
}) => {
  return (
    <div
      className={classNames([className, styles.ContentLoader])}
      style={style}
    />
  );
};
