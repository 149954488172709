import { Flex, Heading, Tabs, capitalize } from '@nex/labs';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import { Animations } from '../library/views/animations';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import styles from '../library/library.module.scss';
import { ArtBoards } from '../library/views/artboards';
import GridIcon from '@nex/icons/svg/misc/grid.svg';
import ListIcon from '@nex/icons/svg/misc/list.svg';
import { useFileUpload } from '@/components/upload/useUpload';
import { Canvases } from '../library/views/canvas';
import { AppIcon } from '@/components/misc/app-icon';
import { SortButton } from '../library/components/sort-button';

const TabsTypes = ['studio', 'animations', 'canvas'] as const;
type TabType = (typeof TabsTypes)[number];

export const Projects = () => {
  const [currentTab, setCurrentTab] = React.useState<TabType>('studio');
  const router = useRouter();

  const getHref = useCallback((tab: string) => {
    return `/projects/${tab}`;
  }, []);

  useEffect(() => {
    const { tab } = router.query;
    if (tab && TabsTypes.includes(tab as TabType)) {
      setCurrentTab(tab as TabType);
    }
  }, [router.query]);

  return (
    <div className={styles.ConsoleWrapper}>
      <Flex.Column gap={24}>
        <Heading.h4
          fontSize="calc(var(--font-h4) - 2px)"
          className="mb-1 flex items-center gap-2"
          weight={700}
        >
          <LogoMark className="inline w-[32px] h-[32px]" />
          <span className="font-light opacity-80">Projects /</span>
          <span className="font-bold">{capitalize(currentTab)}</span>
          <br />
        </Heading.h4>
      </Flex.Column>
      <Flex.Column gap={24} className="mt-[24px]">
        <Flex.Column gap={24}>
          <Flex.Row gap={24}>
            <Tabs
              selectedKey={currentTab}
              defaultTab="studio"
              size="sm"
              centered={false}
            >
              {/* <Tabs.Tab dataKey="all" title="All" href={getHref('all')} /> */}
              <Tabs.Tab
                dataKey="studio"
                title={
                  <Flex alignItems="center" gap={6} className="relative z-[1]">
                    <AppIcon
                      type="studio"
                      color="#000"
                      width={18}
                      height="auto"
                    />
                    Studio
                  </Flex>
                }
                href={getHref('studio')}
              />

              <Tabs.Tab
                dataKey="animations"
                title={
                  <Flex alignItems="center" gap={6} className="relative z-[1]">
                    <AppIcon
                      type="animator"
                      color="#000"
                      width={18}
                      height="auto"
                    />
                    Animations
                  </Flex>
                }
                href={getHref('animations')}
              />
              <Tabs.Tab
                dataKey="canvas"
                title={
                  <Flex alignItems="center" gap={6} className="relative z-[1]">
                    <AppIcon
                      type="canvas"
                      color="#000"
                      width={18}
                      height="auto"
                    />
                    Canvas
                  </Flex>
                }
                href={getHref('canvas')}
              />
            </Tabs>
            <SortButton />
          </Flex.Row>

          {currentTab === 'studio' && <ArtBoards />}
          {currentTab === 'animations' && <Animations />}
          {currentTab === 'canvas' && <Canvases />}
        </Flex.Column>
      </Flex.Column>
    </div>
  );
};
