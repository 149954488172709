import { createSmartApi } from '@lib/smart-query';
import type {
  Collection,
  ImageReactionType,
  Pagination,
  Preset,
} from '@nex/types';

import queryString from 'query-string';

export const promptApiCreator = createSmartApi({
  key: 'prompt',
  endpoints: (builder) => ({
    getEnhanceSession: builder.query<
      {
        id: string;
        share?: boolean;
      },
      { upscalerSession: any }
    >({
      key: 'enhance-session-id',
      queryFn: ({ id }) => ({
        url: `/upscaler/sessions/${id}`,
      }),
    }),

    getCollection: builder.query<
      {
        search?: string;
        limit?: number;
        workspaceId?: string;
      },
      {
        collections: Collection[];
        pagination: Pagination;
      }
    >({
      key: 'collections',
      isInfinite: true,
      queryFn: ({ search, limit, workspaceId }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/workspaces/${workspaceId}/collections`,
            query: {
              search,
              limit: 20,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    getArtboards: builder.query<
      {
        search?: string;
        limit?: number;
        shared?: boolean;
        workspaceId?: string;
      },
      {
        artboards: any[];
        pagination: Pagination;
      }
    >({
      key: 'artboards',
      isInfinite: true,
      queryFn: ({ search, limit, shared, workspaceId }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/workspaces/${workspaceId}/artboards`,
            query: {
              search,
              limit: limit || 20,
              shared,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    getCollectionById: builder.query({
      key: 'collection-id',
      isInfinite: true,
      queryFn: ({ id, workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}/collections/${id}`,
      }),
    }),

    votePrompt: builder.mutation<
      {
        id: string;
        vote: 'up' | 'down';
      },
      any
    >({
      invalidatesQueries: ['generate-session'],
      mutationFn: ({ id, vote }) => ({
        url: `/generations/${id}/vote/${vote}`,
        method: 'PATCH',
      }),
    }),

    reactToImage: builder.mutation<
      {
        workspaceId: string;
        imageId: string;
        reaction: ImageReactionType;
      },
      any
    >({
      invalidatesQueries: ['generate-session'],
      mutationFn: ({ imageId, workspaceId, reaction }) => ({
        url: `/v2/workspaces/${workspaceId}/images/${imageId}/reactions/${reaction}`,
        method: 'POST',
      }),
    }),

    updateCollection: builder.mutation<
      {
        id?: string;
        name?: string;
        description?: string;
        images?: string[];
        workspaceId: string;
        type?: 'add' | 'remove';
      },
      {
        collection: Collection;
        pagination: Pagination;
      }
    >({
      invalidatesQueries: ['collections', 'collection-id'],
      mutationFn: ({ id, name, description, images, type, workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}/collections/${id}/images${
          type === 'remove' ? `/${images?.[0]}` : ''
        }`,
        method: type === 'add' ? 'POST' : 'DELETE',
        body: type
          ? { images }
          : {
              name,
              description,
              images,
            },
      }),
    }),

    deleteCollection: builder.mutation({
      invalidatesQueries: ['collections'],
      mutationFn: ({ id, workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}/collections/${id}`,
        method: 'DELETE',
      }),
    }),

    sharePrompt: builder.mutation({
      invalidatesQueries: ['generate-session', 'generate-session-id'],
      mutationFn: ({ id }) => ({
        url: `/prompts/sessions/${id}/share`,
        method: 'PATCH',
      }),
    }),

    getEnhanceHistory: builder.query({
      key: 'enhance-session',
      isInfinite: true,
      queryFn: ({ sort, limit = 3, search }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/upscaler/sessions`,
            query: {
              sort,
              limit,
              search,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    enhance: builder.mutation({
      key: 'enhance',
      mutationFn: (data) => ({
        url: '/upscaler',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    getPresetById: builder.query<
      {
        id?: string;
      },
      { preset: Preset }
    >({
      key: 'generate-preset-id',
      queryFn: ({ id }) => ({
        url: `/presets/${id}`,
      }),
    }),

    getFeeds: builder.query({
      key: 'feed',
      isInfinite: true,
      queryFn: () => ({
        url: `/feed`,
      }),
    }),

    likePost: builder.mutation({
      mutationFn: ({ id }) => ({
        url: `/showcase/${id}/likes`,
        method: 'POST',
      }),
      invalidatesQueries: ['feed-id'],
    }),

    getFeedById: builder.query({
      key: 'feed-id',
      queryFn: ({ id }) => ({
        url: `/feed/${id}`,
      }),
    }),

    getFeedLikes: builder.query({
      key: 'feed-likes',
      queryFn: () => ({
        url: `/profile/feed/likes`,
      }),
    }),

    getPresets: builder.query<
      {
        limit?: number;
        search?: string;
      },
      { presets: Preset[] }
    >({
      key: 'generate-preset',
      isInfinite: true,
      queryFn: ({ limit = 10, search }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/presets`,
            query: {
              search,
              limit,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    getModelPresets: builder.query<
      {
        limit?: number;
        search?: string;
        modelId: string;
      },
      { presets: Preset[] }
    >({
      key: 'model-presets',
      isInfinite: true,
      queryFn: ({ limit = 10, search, modelId }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/models/${modelId}/presets`,
            query: {
              search,
              limit,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),
  }),
});

export const {
  useGetCollectionQuery,
  useDeleteCollectionMutation,
  useEnhanceMutation,
  useGetPresetsQuery,
  useGetEnhanceSessionQuery,
  useGetPresetByIdQuery,
  useUpdateCollectionMutation,
  useGetCollectionByIdQuery,
  useSharePromptMutation,
  useGetFeedLikesQuery,
  useVotePromptMutation,
  useGetArtboardsQuery,
  useGetFeedsQuery,
  useGetFeedByIdQuery,
  useGetEnhanceHistoryQuery,
  useLikePostMutation,
  useReactToImageMutation,
  useGetModelPresetsQuery,
  queryKeys: promptQueryKeys,
} = promptApiCreator;
