import { Text } from '@nex/labs';
import Link from 'next/link';
import React, { memo, useMemo } from 'react';

export interface BreadcrumbItem {
  label: string;
  href?: string;
  onClick?: () => void;
}

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
  separator?: React.ReactNode;
  maxItems?: number;
  className?: string;
  activeClassName?: string;
  size?: 'sm' | 'md' | 'lg';
  separatorClassName?: string;
}

const BreadcrumbBase = ({
  items,
  separator,
  maxItems = 3,
  size = 'md',
  className = '',
  activeClassName = '',

  separatorClassName = '',
}: BreadcrumbProps) => {
  const displayedItems = useMemo(() => {
    if (!maxItems || items.length <= maxItems) return items;

    const start = items.slice(0, 1);
    const end = items.slice(-2);
    return [...start, { label: '...' }, ...end];
  }, [items, maxItems]);

  const textSizes = {
    sm: 'text-[12px]',
    md: 'text-sm',
    lg: 'text-md',
  };

  const defaultClassName = `${textSizes[size]} text-gray-600 hover:text-gray-900 transition-colors cursor-pointer`;
  const defaultActiveClassName = 'text-gray-900 opacity-60';
  const defaultSeparatorClassName = 'mx-2 text-gray-400';

  return (
    <nav aria-label="Breadcrumb" className="flex items-center flex-wrap">
      <ol className="flex items-center">
        {displayedItems.map((item, index) => {
          const isLast = index === displayedItems.length - 1;
          const CallToAction =
            typeof item.onClick === 'function' ? 'button' : Link;

          return (
            <li key={index} className="flex items-center">
              {item.href || item.onClick ? (
                <CallToAction
                  href={item.href!}
                  className={`${defaultClassName} ${className} ${isLast ? `${defaultActiveClassName} ${activeClassName}` : ''}`}
                  aria-current={isLast ? 'page' : undefined}
                  {...(typeof item.onClick === 'function'
                    ? { onClick: item.onClick }
                    : {})}
                >
                  <Text weight={500} className={`${textSizes[size]}`}>
                    {item.label}
                  </Text>
                </CallToAction>
              ) : (
                <Text
                  weight={600}
                  className={`${defaultClassName} opacity-60 ${className} ${isLast ? `${defaultActiveClassName} ${activeClassName}` : ''}`}
                  aria-current={isLast ? 'page' : undefined}
                >
                  {item.label}
                </Text>
              )}

              {!isLast && (
                <Text
                  weight={400}
                  className={`${textSizes[size]} ${defaultSeparatorClassName} ${separatorClassName}`}
                >
                  {separator || '/'}
                </Text>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export const Breadcrumb = Object.assign(BreadcrumbBase, {
  Item: ({ children }: { children: React.ReactNode }) => children,
});

export default Breadcrumb;
