import { usePathname } from 'next/navigation';
import { Flex, Text, Tooltip } from '@nex/labs';
import Link from 'next/link';
import classNames from 'classnames';

import Home from '@nex/icons/svg/console/home.svg';
import Search from '@nex/icons/svg/misc/search.svg';
import { AppIcon } from '@/components/misc/app-icon';

import styles from './header-nav-menu.module.scss';

const MENU_ITEMS = [
  {
    label: 'Home',
    Icon: Home,
    href: '/console',
  },
  {
    label: 'Marko',
    Icon: ({ ...props }) => <AppIcon color="#000" type="marko" {...props} />,
    href: '/marko',
  },
  {
    label: 'Studio',
    Icon: ({ ...props }) => <AppIcon color="#000" type="studio" {...props} />,
    href: '/studio',
  },
  {
    label: 'Canvas',
    Icon: ({ ...props }) => <AppIcon color="#000" type="canvas" {...props} />,
    href: '/canvas',
  },
  {
    label: 'Enhancer',
    Icon: ({ ...props }) => <AppIcon color="#000" type="enhancer" {...props} />,
    href: '/enhance',
  },
  {
    label: 'Animator',
    Icon: ({ ...props }) => <AppIcon color="#000" type="animator" {...props} />,
    href: '/animator',
  },
  {
    label: 'Search',
    Icon: Search,
    href: '/search',
  },
];

type HeaderNavMenuProps = {
  className?: string;
  hideLabel?: boolean;
};

export const HeaderNavMenu = (props: HeaderNavMenuProps) => {
  const pathname = usePathname();
  return (
    <Flex
      responsiveScroll
      className={classNames(styles.HeaderNavMenu, props.className)}
    >
      {MENU_ITEMS.slice(0, props?.hideLabel ? 6 : MENU_ITEMS.length - 1)

        .map((item, index) => (
          <Link
            key={item.label}
            href={item.href}
            className={classNames([
              styles.HeaderNavMenuItem,
              pathname?.startsWith(item.href) ||
              (index === 0 && pathname === '/')
                ? 'opacity-100'
                : 'opacity-50',
              pathname?.includes(item.href) &&
                'border-solid border-b-2 border-[var(--primary-theme)] pb-[4px] -mt-[2px] -mb-[8px]',
            ])}
          >
            <Tooltip tooltip={item.label} position="bottom">
              <item.Icon width="24" height="24" color={'var(--text-black)'} />
            </Tooltip>
            <Text
              className={classNames(
                styles.HeaderNavMenuItemLabel,
                props.hideLabel && 'hidden'
              )}
              weight={500}
            >
              {item.label}
            </Text>
          </Link>
        ))}
    </Flex>
  );
};
