import React from 'react';

type RenderIfProps<T = any> = {
  /**
   * The condition that determines if children should be rendered
   * Can be a boolean or a function that returns a boolean
   */
  condition: boolean | ((value?: T) => boolean);

  /**
   * The content to render when condition is true
   */
  children: React.ReactNode;

  /**
   * Optional fallback content to render when condition is false
   */
  fallback?: React.ReactNode;

  /**
   * Optional value to pass to condition function
   */
  value?: T;

  /**
   * Whether to keep the component mounted but hidden (useful for animations)
   * @default false
   */
  keepMounted?: boolean;
};

/**
 * A flexible conditional rendering component with TypeScript support
 *
 * @example
 * // Basic usage
 * <RenderIf condition={isLoggedIn}>
 *   <UserDashboard />
 * </RenderIf>
 *
 * @example
 * // With fallback
 * <RenderIf condition={hasData} fallback={<LoadingSpinner />}>
 *   <DataView data={data} />
 * </RenderIf>
 *
 * @example
 * // With function condition
 * <RenderIf
 *   condition={(value) => value > 10}
 *   value={score}
 * >
 *   <HighScoreMessage />
 * </RenderIf>
 *
 * @example
 * // Keep mounted but hidden
 * <RenderIf condition={isVisible} keepMounted>
 *   <AnimatedComponent />
 * </RenderIf>
 */
export const RenderIf = <T,>({
  condition,
  children,
  fallback = null,
  value,
  keepMounted = false,
}: RenderIfProps<T>) => {
  const shouldRender =
    typeof condition === 'function' ? condition(value) : condition;

  if (keepMounted) {
    return (
      <div style={{ display: shouldRender ? undefined : 'none' }}>
        {children}
      </div>
    );
  }

  return <>{shouldRender ? children : fallback}</>;
};

/**
 * A conditional rendering component that renders children when condition is false
 *
 * @example
 * <RenderUnless condition={isMobile}>
 *   <DesktopComponent />
 * </RenderUnless>
 */
export const RenderUnless = <T,>(props: RenderIfProps<T>) => (
  <RenderIf
    {...props}
    condition={
      typeof props.condition === 'function'
        ? (value) => !(props.condition as (value?: T) => boolean)(value)
        : !props.condition
    }
  />
);

type RenderWhenProps<T = any> = Omit<RenderIfProps<T>, 'condition'> & {
  conditions: (boolean | ((value?: T) => boolean))[];
  operator?: 'AND' | 'OR';
};

/**
 * A conditional rendering component that supports multiple conditions
 * and an operator to determine how to evaluate them
 *
 * @example
 * <RenderWhen
 *  conditions={[isLoggedIn, hasData]}
 *  operator="AND"
 *  fallback={<LoadingSpinner />}
 * >
 *  <UserDashboard />
 * </RenderWhen>
 */

export const RenderWhen = <T,>({
  conditions,
  operator = 'AND',
  ...props
}: RenderWhenProps<T>) => {
  const evaluateCondition = (condition: boolean | ((value?: T) => boolean)) => {
    return typeof condition === 'function' ? condition(props.value) : condition;
  };

  const shouldRender =
    operator === 'AND'
      ? conditions.every(evaluateCondition)
      : conditions.some(evaluateCondition);

  return <RenderIf {...props} condition={shouldRender} />;
};
