// import { useQuery, useQueryClient } from '@tanstack/react-query';
// import React, { useMemo } from 'react';

// export const useQueryData = (
//   queries: Array<string | object>,
//   options: {
//     enabled?: boolean;
//     isInfinite?: boolean;
//     select?: (data: any) => any;
//   }
// ) => {
//   const queryClient = useQueryClient();

//   const queryKey = queries.flat()?.filter(Boolean);
//   const queryFallBack: any = queryClient.getQueryState(
//     queries.flat()?.filter(Boolean)
//   );

// const { data: queryData, isLoading: isDataLoading } = useQuery(
//   queryKey,
//   () =>
//     typeof queryFallBack === 'undefined'
//       ? queryClient.getQueryCache().find(queryKey)?.state?.data
//       : [],
//   {
//     enabled: options.enabled,
//   }
// );
//   console.log('queryData', queryData, queryFallBack);
//   const data = useMemo(() => {
//     if (!options.enabled && 'enabled' in options) return null;
//     const finalData =
//       typeof queryFallBack === 'undefined' ? queryData : queryFallBack?.data;

//     let data = finalData;

//     if (options.isInfinite) {
//       data = finalData?.pages?.flat();
//     }

//     if (options.select) {
//       return options.select(data);
//     }

//     return data;
//   }, [options, queryData, queryFallBack]);

//   const isLoading = useMemo(() => {
//     if (options.enabled) {
//       return isDataLoading || queryFallBack?.isLoading;
//     }

//     return false;
//   }, [queryData, options.enabled, isDataLoading, queryFallBack]);

//   return {
//     data,
//     isLoading,
//   };
// };

import { useQueryClient, useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

export const useQueryData = (
  queries: Array<string | object>,
  options: {
    enabled?: boolean;
    isInfinite?: boolean;
    select?: (data: any) => any;
  }
) => {
  const queryClient = useQueryClient();
  const queryKey = queries.flat()?.filter(Boolean);
  let queryData: any = queryClient.getQueryState(queryKey);

  useQuery(queryKey, () => queryClient.getQueryCache().find(queryKey)?.state, {
    enabled: false,
  });

  const data = useMemo(() => {
    if (!options.enabled && 'enabled' in options) return null;

    let data = queryData?.data;

    if (options.isInfinite) {
      data = queryData?.data?.pages?.flat();
    }

    if (options.select) {
      return options.select(data);
    }

    return data;
  }, [queryData, options]);

  const isLoading = useMemo(() => {
    if (options.enabled) {
      return queryData?.status === 'loading';
    }

    return false;
  }, [queryData, options.enabled]);

  return {
    data,
    isLoading,
  };
};
