import {
  Avatar,
  CallToAction,
  extractImage,
  Flex,
  getDataIcons,
  humanReadableSize,
  Img,
  pluralize,
  Popover,
  Text,
  truncateText,
  useFeedback,
} from '@nex/labs';
import { formatDistanceToNow } from 'date-fns';
import Link from 'next/link';
import React, { useCallback, useRef, useMemo } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import FolderIcon from '@nex/icons/svg/misc/folder.svg';
import SessionIcon from '@nex/icons/svg/misc/chat.svg';
import styles from './folder-card.module.scss';
import classNames from 'classnames';
import {
  useDeleteFolderMutation,
  useDeleteUserFileMutation,
} from '@/state/query/block';
import { CreateFolderModal } from '@/features/console';

interface IFolderCard<T> {
  data?: T extends Array<infer U> ? U[] : T[];
  type?: 'asset' | 'sessions';
  limit?: number;
  view?: 'grid' | 'list';
  renderInline?: boolean;
  onFolderClick?: (folder: T) => void;
  onContextSelect?: (context: T) => void;
}

const FolderResource = {
  asset: {
    icon: FolderIcon,
    url: '/assets/folders/',
  },
  sessions: {
    icon: SessionIcon,
    url: '/sessions/',
  },
};

const FolderItem = <T extends unknown>({
  data,
  type,
  view,
  renderInline,
  onFolderClick,
  onContextSelect,
  style,
}: IFolderCard<T> & { style?: React.CSSProperties }) => {
  const FolderData = FolderResource[type || 'asset'];
  const isListView = view === 'list';
  const CallToActionButton = renderInline ? 'button' : Link;
  const hasContext = !!onContextSelect;
  const folderData = useMemo(() => data?.[0] as any, [data]);

  return (
    <CallToActionButton
      href={renderInline ? '' : `${FolderData.url}${folderData?.id}`}
      {...(renderInline ? { onClick: () => onFolderClick?.(folderData) } : {})}
      style={style}
      className="w-full"
    >
      <div className={styles.FolderArea__card}>
        {!!extractImage(folderData) ? (
          <Img src={extractImage(folderData)} alt={folderData?.name} />
        ) : (
          <div className={styles.alternateBG}>
            <FolderData.icon width={24} />
          </div>
        )}

        <div className={styles.FolderArea__card__content}>
          <Flex gap={4} alignItems="center">
            {view !== 'grid' && <FolderData.icon width={16} height={16} />}

            <Text
              fontSize="var(--font-h6)"
              weight={600}
              className="flex-1"
              noOfLines={1}
            >
              {folderData?.name}
            </Text>

            {!isListView && <FolderActions asset={folderData} />}
          </Flex>

          <Flex.Column className={styles.FolderArea__card__action}>
            {hasContext && (
              <CallToAction.button
                size="xs"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onContextSelect?.(folderData);
                }}
                className={classNames([
                  'whitespace-pre',
                  styles.Generations__Context,
                ])}
              >
                <Text weight={600} fontSize="var(--font-caption)">
                  Use folder
                </Text>
              </CallToAction.button>
            )}

            {folderData?.size > 0 && (
              <Text
                weight={600}
                fontSize="var(--font-caption)"
                className="opacity-70"
              >
                {pluralize('file', folderData?.fileCount)} |{' '}
                {humanReadableSize(folderData?.size)}
              </Text>
            )}

            <Flex
              fullWidth
              gap={isListView ? 24 : 8}
              justifyContent="space-between"
              alignItems="center"
              className="flex-1"
            >
              {folderData?.updatedAt &&
                typeof onFolderClick === 'undefined' && (
                  <Text
                    className="opacity-70 mt-auto"
                    fontSize="var(--font-caption)"
                    weight={600}
                  >
                    {formatDistanceToNow(new Date(folderData?.updatedAt))}
                    {' ago'}
                  </Text>
                )}

              <Avatar
                alt={folderData?.creator?.name}
                src={folderData?.creator?.photo}
                size={24}
              />

              {isListView && <FolderActions asset={folderData} />}
            </Flex>
          </Flex.Column>
        </div>
      </div>
    </CallToActionButton>
  );
};

export const FolderCardVirtualized = <T extends unknown>({
  data,
  type = 'asset',
  limit = 20,

  renderInline = false,
  onFolderClick,
  onContextSelect,
}: IFolderCard<T>) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const limitedData = useMemo(() => {
    return data?.slice(0, limit || data?.length) || [];
  }, [data, limit]);

  const virtualizer = useVirtualizer({
    count: limitedData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 80,
    overscan: 5,
    horizontal: false,
  });

  if (!limitedData?.length) return null;

  return (
    <div
      ref={parentRef}
      className={classNames([
        styles.FolderGrid,
        styles.list,
        styles['virtual-scroll-container'],
      ])}
      style={{
        height: '100%',
        maxHeight: '500px',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          position: 'relative',
          height: `${virtualizer.getTotalSize()}px`,
        }}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const itemData = [limitedData[virtualRow.index]];
          return (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <FolderItem
                data={itemData as any}
                type={type}
                view="list"
                renderInline={renderInline}
                onFolderClick={onFolderClick}
                onContextSelect={onContextSelect}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const FolderCard = <T extends unknown>({
  data,
  ...rest
}: IFolderCard<T>) => {
  if (rest.view === 'list') {
    return <FolderCardVirtualized data={data} {...rest} />;
  }

  return (
    <div className={classNames([styles.FolderGrid, styles.grid])}>
      {data
        ?.slice(0, data?.length)
        ?.map((data: any, index: number) => (
          <FolderItem key={index} data={[data] as any} {...rest} />
        ))}
    </div>
  );
};

const FolderActions = ({
  asset,
  onViewAsset,
}: {
  asset: any;
  onViewAsset?: () => void;
}) => {
  const { createDisclosure, createToast } = useFeedback();
  const { mutateAsync: deleteFolder } = useDeleteFolderMutation({
    onError: (error: any) => {
      createToast({
        message: error?.message || 'An error occurred',
        variant: 'error',
      });
    },
    onSuccess: () => {
      createToast({
        message: 'File deleted successfully',
      });
    },
  });

  const [show, setShow] = React.useState(false);

  return (
    <span
      tabIndex={0}
      role="button"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Popover size="sm">
        <Popover.Trigger>
          <button>
            <img
              src={getDataIcons('more', '#000')}
              className="h-3.5 w-3.5 object-contain"
            />
          </button>
        </Popover.Trigger>

        <Popover.Content isDropdown>
          <button onClick={onViewAsset}>View</button>
          <button
            onClick={() => {
              setShow(true);
            }}
          >
            Move to folder
          </button>
          <button
            onClick={async () => {
              await createDisclosure({
                title: `Delete ${truncateText(asset?.name, 20)}`,
                message: 'Are you sure you want to delete this Folder?',
                confirmText: 'Delete',
              });

              deleteFolder({
                id: asset.id,
              });
            }}
          >
            Delete
          </button>
        </Popover.Content>
      </Popover>
      <CreateFolderModal
        show={show}
        type="folder"
        onClose={() => setShow(false)}
        data={asset}
      />
    </span>
  );
};
