import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { SkipNavContent, SkipNavLink, transitions, useHover } from '@nex/labs';
import { useUser } from '@clerk/nextjs';

import Router from 'next/router';
import { usePathname, useRouter } from 'next/navigation';
import { motion } from 'framer-motion';

import { useGlobalStore, useUserStore } from '@/state/useStore';
import styles from './console-layout.module.scss';
import { ConsoleHeaderNav } from './component/console-header-nav';
import { ConsoleSideBar } from './component/console-sidebar';

export const ConsoleLayout: React.FC<
  {
    title?: string;
    bare?: boolean;
  } & PropsWithChildren
> = ({ children, title }) => {
  const router = useRouter();
  const pathname = usePathname();

  const getActive = (href: string) => {
    return pathname === href ? styles.active : '';
  };
  const { setOverlayLoading, setModal } = useGlobalStore();
  const getSlideIn = transitions.useSlide();
  const { user } = useUser();

  const { profile } = useUserStore();

  useEffect(() => {
    const redirectTo = window.sessionStorage.getItem('redirectTo');

    if (redirectTo) {
      setOverlayLoading({
        loading: true,
        message: 'Preparing your artboard and Redirecting...',
      });

      if (profile?.id || user) {
        setTimeout(() => {
          router.push(redirectTo);
          window.sessionStorage.removeItem('redirectTo');
          setOverlayLoading(false);
        }, 1200);
      }
    }
  }, [profile?.id, user]);

  useEffect(() => {
    if (typeof window !== 'undefined')
      document.documentElement.style.setProperty(
        '--feedback-area-left',
        'var(--sidebar-width)'
      );

    return () => {
      if (typeof window !== 'undefined')
        document.documentElement.style.setProperty(
          '--feedback-area-left',
          '0px'
        );
    };
  }, []);

  return (
    <div className={`${styles.ConsoleLayout}`}>
      <SkipNavLink focusSelector="maincontent" />

      <div className={styles.ConsoleLayoutMain}>
        <div className={styles.ConsoleLayoutMainNav}>
          <ConsoleHeaderNav />
        </div>

        <ConsoleSideBar />
        <motion.main
          {...getSlideIn({ x: 3 })}
          className={styles.ConsoleLayoutMainContent}
        >
          <SkipNavContent focusSelector="maincontent">
            <div className={styles.MainContent}>{children}</div>
          </SkipNavContent>
        </motion.main>
      </div>
    </div>
  );
};
