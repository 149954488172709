import { createSmartApi } from '@lib/smart-query';
import { Pagination } from '@nex/types';
import queryString from 'query-string';

export const blockApiCreator = createSmartApi({
  key: 'prompt',
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      key: 'all-users',
      queryFn: ({ search, limit = 10 }) => ({
        url: queryString.stringifyUrl(
          {
            url: '/v2/users',
            query: {
              search,
              limit,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    generate: builder.mutation({
      key: 'generate-artboard',
      mutationFn: ({ id, data }) => {
        return {
          url: `/artboards/${id}/groups`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: data,
        };
      },
      invalidatesQueries: ['generations', 'artboards'],
    }),

    addCollaborator: builder.mutation({
      key: 'add-collaborators',
      mutationFn: ({ id, data }) => ({
        url: `/artboards/${id}/collaborators`,
        method: 'POST',
        body: data,
      }),
      invalidatesQueries: ['get-artboard', 'generations', 'artboards'],
    }),

    removeCollaborator: builder.mutation({
      key: 'remove-collaborators',
      mutationFn: ({ id, userId }) => ({
        url: `/artboards/${id}/collaborators/${userId}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['generations'],
    }),

    editArtBoard: builder.mutation({
      key: 'edit-artboard',
      mutationFn: ({ id, data }) => ({
        url: `/artboards/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesQueries: ['get-artboard', 'generations', 'artboards'],
    }),

    deleteArtboard: builder.mutation({
      key: 'delete-artboard',
      mutationFn: ({ id }) => ({
        url: `/artboards/${id}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['generations'],
    }),

    getGeneration: builder.query({
      key: 'generation',
      queryFn: ({ id }) => ({
        url: `/generations/${id}`,
      }),
    }),

    getV2Generation: builder.query({
      key: 'generation',
      queryFn: ({ id }) => ({
        url: `/v2/generations/${id}`,
      }),
    }),

    getUserFiles: builder.query({
      key: 'user-assets',
      isInfinite: true,
      queryFn: ({ creator, search, folderId = 'root', sort, filter }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/folders/${folderId}/files`,
            query: {
              creator,
              search,
              sort,
              filter,
            },
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
      }),
    }),

    getUserFile: builder.query({
      key: 'user-file',
      isInfinite: true,
      queryFn: ({ id }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/files/${id}`,
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
      }),
    }),

    getUserFolders: builder.query({
      key: 'user-folders',
      isInfinite: true,
      queryFn: ({
        creator,
        search,
        limit,
        folderId = 'root',
        sort,
        filter,
      }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/folders/${folderId}/folders`,
            query: {
              creator,
              search,
              sort,
              limit,
              filter: filter,
            },
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
      }),
    }),

    getUserFolder: builder.query({
      key: 'user-folder',
      queryFn: ({ id }) => ({
        url: `/v2/folders/${id}`,
      }),
    }),

    deleteFolder: builder.mutation({
      key: 'delete-folder',
      mutationFn: ({ id }) => ({
        url: `/v2/folders/${id}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['user-folders', 'user-folder'],
    }),

    updateFolder: builder.mutation({
      key: 'update-folder',
      mutationFn: ({ id, data }) => ({
        url: `/v2/folders/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesQueries: ['user-folders', 'user-folder'],
    }),

    deleteUserFile: builder.mutation({
      key: 'delete-user-file',
      mutationFn: ({ id }) => ({
        url: `/v2/files/${id}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['user-assets', 'user-folders'],
    }),

    createOrUpdateSketch: builder.mutation({
      key: 'create-or-update-sketch',
      mutationFn: ({ workspaceId, sketchId, state, thumbnail, snapshot }) => {
        const ID =
          typeof sketchId === 'string' && !!sketchId && sketchId !== 'new';

        return {
          url: `/v2/workspaces/${workspaceId}/sketches${
            ID ? `/${sketchId}` : ''
          }`,
          method: ID ? 'PUT' : 'POST',
          body: {
            state,
            thumbnail,
            snapshot,
          },
        };
      },

      invalidatesQueries: ['sketches'],
    }),

    getSketches: builder.query({
      key: 'sketches',
      isInfinite: true,
      queryFn: ({ workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}/sketches`,
      }),
    }),

    deleteSketch: builder.mutation({
      key: 'delete-sketch',
      mutationFn: ({ workspaceId, sketchId }) => ({
        url: `/v2/workspaces/${workspaceId}/sketches/${sketchId}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['sketches'],
    }),

    getSketch: builder.query({
      key: 'sketch',
      queryFn: ({ workspaceId, sketchId }) => ({
        url: `/v2/workspaces/${workspaceId}/sketches/${sketchId}`,
      }),
    }),

    getGenerations: builder.query({
      key: 'generations',
      queryFn: ({ id }) => ({
        url: `/artboards/${id}`,
      }),
    }),

    getModels: builder.query({
      key: 'models',
      queryFn: () => ({
        url: '/v2/models',
      }),
    }),

    /** @deprecated use useCreateFileMutation */
    getUploadURL: builder.mutation<
      any,
      {
        url: string;
        fields: Record<string, string>;
      }
    >({
      mutationFn: (type) => ({
        url: `/v2/assets/upload?type=${type}`,
        method: 'GET',
      }),
    }),

    createArtBoard: builder.mutation({
      key: 'create-artboard',
      mutationFn: (data) => ({
        url: '/v2/artboards',
        method: 'POST',
        body: data,
      }),
    }),

    getModelById: builder.mutation({
      key: 'model-id',
      mutationFn: ({ id }) => ({
        url: `/models/${id}`,
      }),
    }),

    getPrompt: builder.mutation({
      key: 'prompt',
      mutationFn: (data) => ({
        url: '/prompts/canvas',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    /** @deprecated use useUploadFileMutation */
    uploadAsset: builder.mutation({
      key: 'upload-asset',
      mutationFn: ({ data, url }) => ({
        url,
        method: 'POST',
        body: data,
        headers: {
          Authorization: undefined,
          'Content-Type': 'multipart/form-data',
        },
        token: '',
      }),
    }),

    uploadFile: builder.mutation({
      key: 'upload-file',
      mutationFn: ({ data, url }) => ({
        url,
        method: 'POST',
        body: data,
        headers: {
          Authorization: undefined,
          'Content-Type': 'multipart/form-data',
        },
        token: '',
      }),
      invalidatesQueries: ['user-assets', 'user-folders'],
    }),

    saveAssets: builder.mutation({
      key: 'save-assets',
      mutationFn: (data) => ({
        url: '/v2/assets',
        method: 'POST',
        body: data,
      }),
      invalidatesQueries: ['user-assets'],
    }),

    getCompositionsInProgress: builder.query({
      key: 'compositions-in-progress',
      queryFn: (data) => ({
        url: `/v2/workspaces/${data.workspaceId}/artboards/${data.artboardId}/compositions/tasks`,
      }),
    }),

    generateFromComposition: builder.mutation({
      key: 'generate-from-composition',
      mutationFn: ({ artboardId, data, workspaceId }) => {
        return {
          url: `/v2/workspaces/${workspaceId}/artboards/${artboardId}/compositions`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesQueries: [
        'get-artboard',
        'compositions-in-progress',
        'artboards',
      ],
    }),

    getArtboard: builder.query({
      key: 'get-artboard',
      queryFn: ({ workspaceId, artboardId, limit }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/workspaces/${workspaceId}/artboards/${artboardId}`,
            query: {
              limit,
            },
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
      }),
      isInfinite: true,
    }),

    getComposition: builder.query({
      key: 'composition',
      queryFn: ({ workspaceId, artboardId, compositionId }) => ({
        url: `/v2/workspaces/${workspaceId}/artboards/${artboardId}/compositions/${compositionId}`,
      }),
    }),

    deleteAsset: builder.mutation({
      key: 'delete-asset',
      mutationFn: ({ workspaceId, assetId }) => ({
        url: `/v2/workspaces/${workspaceId}/assets/${assetId}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['user-assets'],
    }),

    /*** @deprecated */
    createFolder: builder.mutation<
      {
        name?: string;
        description?: string;
        images?: string[];
        folderId?: string;
      },
      {
        folders: any;
        folder: any;
        pagination: Pagination;
      }
    >({
      invalidatesQueries: ['user-folders'],
      mutationFn: ({ name, description, images, folderId = 'root' }) => ({
        url: `/v2/folders`,
        method: 'POST',
        body: {
          name,
          description,
          folderId,
          images,
        },
      }),
    }),

    createFile: builder.mutation<
      {
        name: string;
        size: number;
        type: string;
        folderId?: string;
      },
      any
    >({
      key: 'create-file',
      mutationFn: (data) => {
        return {
          url: '/v2/files',
          method: 'POST',
          body: data,
        };
      },
    }),

    updateFile: builder.mutation<
      {
        id: string;
        data: {
          name?: string;
          size?: number;
          type?: string;
          folderId?: string;
        };
      },
      any
    >({
      key: 'update-file',
      mutationFn: ({ id, data }) => {
        return {
          url: `/v2/files/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesQueries: ['user-assets', 'user-folders'],
    }),
  }),
});

export const {
  useGetModelsQuery,
  useGetAllUsersQuery,
  useCreateArtBoardMutation,
  useGetGenerationQuery,
  useGenerateMutation,
  useGetPromptMutation,
  useRemoveCollaboratorMutation,
  useAddCollaboratorMutation,
  useGetModelByIdMutation,
  useDeleteSketchMutation,
  useGetUserFilesQuery,
  useEditArtBoardMutation,
  useGetGenerationsQuery,
  useGetUploadURLMutation,
  useUploadAssetMutation,
  useSaveAssetsMutation,
  useGetSketchQuery,
  useGetV2GenerationQuery,
  useDeleteAssetMutation,
  useDeleteArtboardMutation,
  useGetUserFoldersQuery,
  useCreateOrUpdateSketchMutation,
  useGetSketchesQuery,
  useGetCompositionsInProgressQuery,
  useGenerateFromCompositionMutation,
  useGetArtboardQuery,
  useGetCompositionQuery,
  useCreateFolderMutation,
  useCreateFileMutation,
  useDeleteUserFileMutation,
  useGetUserFileQuery,
  useUploadFileMutation,
  useUpdateFileMutation,
  useGetUserFolderQuery,
  useDeleteFolderMutation,
  useUpdateFolderMutation,
  queryKeys: blockQueryKeys,
} = blockApiCreator;
