import React, { useCallback, useMemo } from 'react';
import {
  CallToAction,
  Flex,
  Heading,
  humanReadableSize,
  Img,
  Text,
  getDataIcons,
  useFeedback,
  Empty,
  truncateText,
} from '@nex/labs';

import { useArtboardStore, useGlobalStore } from '@/state/useStore';
import { useFileUpload } from '@/components/upload/useUpload';
import { usePostHog } from 'posthog-js/react';
import styles from './modals.module.scss';
import { ASSETS_CONSTANTS } from '../../utils/constants';
import {
  useCreateFileMutation,
  useCreateFolderMutation,
  useUploadFileMutation,
} from '@/state/query/block/index';
import Router from 'next/router';
import Breadcrumb from '@/components/misc/bread-crumb';
import { Accordion } from '@/components/accordion';
import { queryClient } from '@lib/query-client';
import FolderIcon from '@nex/icons/svg/misc/folder.svg';
import FileIcon from '@nex/icons/svg/misc/file.svg';
const folderCache = new Map();
const UPLOAD_WORKER_PATH = '/workers/upload-worker.js';

export const AssetsModal = () => {
  const { createDisclosure, createToast } = useFeedback();
  const {
    setAssets,
    assets,
    closeModal,
    modal,
    setUploadProgress,
    upload: uploadProgress,
  } = useGlobalStore();
  const { setBlock } = useArtboardStore();
  const uploadOptions = useMemo(() => (modal?.props as any).options, [modal]);

  const { mutateAsync: createFile, isLoading: isCreatingFile } =
    useCreateFileMutation();
  const { mutateAsync: uploadFile, isLoading: isUploadingFile } =
    useUploadFileMutation({});

  const { mutateAsync: uploadFolder, isLoading: isFolderLoading } =
    useCreateFolderMutation({});

  const { onFileUpload } = useFileUpload();
  const posthog = usePostHog();

  // Create folders recursively and cache their IDs
  const createFolderPath = useCallback(
    async (folderPath: string[]) => {
      let currentFolderId = (Router.query.folderId as string) || 'root';
      if (!folderPath.length) return currentFolderId;

      for (let i = 0; i < folderPath.length; i++) {
        const pathKey = folderPath.slice(0, i + 1).join('/');

        if (folderCache.has(pathKey)) {
          currentFolderId = folderCache.get(pathKey);
          continue;
        }

        const directParent = folderPath.slice(0, i);
        const directParentId = directParent.length
          ? folderCache.get(directParent.join('/'))
          : (Router.query.folderId as string) || 'root';

        try {
          const res = await uploadFolder({
            name: folderPath[i],
            folderId: directParentId,
          });

          if (!res.folder?.id) {
            throw new Error('Failed to create folder');
          }

          currentFolderId = res.folder.id;
          folderCache.set(pathKey, currentFolderId);
        } catch (error) {
          console.error(`Failed to create folder: ${pathKey}`, error);
          throw error;
        }
      }

      return currentFolderId;
    },
    [uploadFolder]
  );

  const handleUploadFiles = useCallback(async () => {
    let totalSize = 0;
    const workers: Worker[] = [];
    // create folders
    try {
      for (const key in assets) {
        const folderPath = (assets[key as keyof typeof assets] as any)
          ?.folderInfo?.folderPath;

        if (folderPath?.length > 0) {
          await createFolderPath(folderPath);
        }
      }

      const files = await Promise.all(
        Object.values(assets).map(async ({ file, ...rest }) => {
          const folderPath = rest.folderInfo?.folderPath || [];
          const folderId =
            folderPath.length > 0
              ? folderCache.get(folderPath.join('/'))
              : (Router.query.folderId as string) || 'root';

          const { file: createdFile, upload } = await createFile({
            name: file.name,
            size: file.size,
            type: file.type,
            folderId,
          });

          const worker = new Worker(UPLOAD_WORKER_PATH, {
            type: 'module',
          });

          setUploadProgress({
            count: Object.values(assets).length || uploadProgress.count,
          });

          worker.onmessage = (e) => {
            const { type, fileId, progress, status, folderId, error } = e.data;

            setUploadProgress({
              progress: {
                [fileId]: {
                  id: fileId,
                  name: file.name,
                  progress,
                  status,
                  folderId,
                  error,
                  preview: rest.preview,
                },
              },
            });

            if (type === 'complete' || type === 'error') {
              queryClient.invalidateQueries({
                queryKey: ['user-folders'],
              });
              queryClient.invalidateQueries({
                queryKey: ['user-assets'],
              });
              worker.terminate();
            }

            if (type === 'complete') {
              createToast({
                message: `Successfully uploaded ${truncateText(file.name, 20)}`,
                variant: 'primary',
              });
            }

            if (type === 'error') {
              createToast({
                message: `Failed to upload ${truncateText(file.name, 20)}. Try again later`,
                variant: 'error',
              });
            }
          };

          workers.push(worker);
          worker.postMessage({ file, upload, folderId });
          closeModal?.();
          totalSize = createdFile.size;
          return createdFile;
        })
      );

      const createdFiles = files
        // .map((file) => file.status === 'fulfilled' && file.value)
        .filter(Boolean);

      console.log('createdFiles', createdFiles);

      if (uploadOptions?.autoAdd && createdFiles.length) {
        const imageURL = createdFiles[0]?.url;
        const imageKey =
          createdFiles[0]?.workspace?.slug + '/' + createdFiles[0]?.key;
        const block = uploadOptions?.block;

        if (uploadOptions?.onUpload) {
          uploadOptions.onUpload(imageURL, imageKey);
        } else {
          setBlock('assets', {
            src: imageURL,
            key: imageKey,
            name: block,
            subMetaInfo: ASSETS_CONSTANTS.find((item) => item.key === block),
            weight: 0.5,
            subMeta: block,
          });
        }
      }

      posthog.capture('asset_uploaded', {
        count: createdFiles.length,
        size: humanReadableSize(totalSize),
      });

      setTimeout(() => {
        setAssets([]);
        createToast({
          message: `View your upload status in the storage drawer`,
          variant: 'primary',
        });
      }, 1000);
    } catch (error) {
      console.error('Failed to upload assets', error);
      createToast({
        message: 'Failed to upload assets. Please try again later',
        variant: 'error',
      });
    } finally {
      folderCache.clear();
    }
  }, [assets, createToast, posthog, createFile, uploadFile]);

  const isLoading = useMemo(
    () => isCreatingFile || isUploadingFile || isFolderLoading,
    [isCreatingFile, isUploadingFile, isFolderLoading]
  );

  const renderProgress = (fileId: string) => {
    const progress = uploadProgress?.progress?.[fileId]?.progress.toFixed(0);
    if (!progress || progress === '100')
      return (
        <CallToAction.button
          size="xs"
          className="w-full"
          variant="secondary"
          onClick={() => {
            const newAssets = exclude(
              assets,
              fileId as keyof typeof assets
            ) as any;
            setAssets(newAssets);
          }}
        >
          Remove
        </CallToAction.button>
      );

    return (
      <div className="w-full mt-2">
        <Flex alignItems="center" gap={4} fullWidth>
          <Text weight={600} casing="capitalize" fontSize="var(--font-caption)">
            {progress}%
          </Text>
          <div className="w-full h-[4px] bg-gray-100 rounded-full">
            <div
              className="h-full bg-[var(--primary-theme)] rounded-full"
              style={{ width: `${progress}%` }}
            />
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <>
      <Flex.Column gap={0} className="mb-6">
        <Heading.h4 weight={600}>Your asset library</Heading.h4>
        <Flex.Row gap={8} alignItems="center" justifyContent="space-between">
          <Text className="opacity-70">
            Upload up to 1GB files to your asset library
          </Text>
          <CallToAction.input
            size="sm"
            variant="secondary"
            className="w-full"
            defaultBehavior={false}
            multiple
            disabled={isLoading}
            acceptedFileTypes={['image/*', 'video/*']}
            onFileUpload={onFileUpload}
            leadingIcon={
              <img
                src={getDataIcons('add', '#000')}
                className="w-[12px] h-[12px]"
              />
            }
          >
            Choose Another File
          </CallToAction.input>
        </Flex.Row>
      </Flex.Column>
      <Flex.Row gap={12}>
        {Object.keys(assets).length ? (
          Object.keys(assets).map((key, i) => {
            const fileType = (
              assets[key as keyof typeof assets] as any
            ).file.type.split('/')[0] as 'image' | 'video' | 'application';
            const isVideo = fileType.includes('video');

            const Components = {
              image: Img,
              video: 'video',
              application: () => (
                <Flex.Column
                  gap={8}
                  className="bg-gray-100 w-full h-[150px] flex items-center justify-center p-4"
                >
                  <FileIcon />
                  <Text
                    weight={600}
                    casing="capitalize"
                    fontSize="var(--font-caption)"
                    noOfLines={1}
                  >
                    {assets[key as any].file.name}
                  </Text>
                </Flex.Column>
              ),
            };
            const Component = Components[fileType];

            return (
              <div key={i} className={styles.AssetsCard}>
                <Component
                  src={(assets[key as any].preview as string) || ''}
                  alt={key}
                  {...(isVideo && {
                    controls: true,
                  })}
                />

                {!!assets[key as any]?.folderInfo?.folderPath.length && (
                  <Accordion
                    title={
                      <Flex alignItems="center" gap={4}>
                        <FolderIcon width={12} className="is-stroke" />
                        <Text
                          weight={600}
                          casing="capitalize"
                          fontSize="var(--font-caption)"
                        >
                          Folder
                        </Text>
                      </Flex>
                    }
                    size="sm"
                    bare
                    className="gap-1"
                  >
                    <Breadcrumb
                      maxItems={2}
                      size="sm"
                      items={assets[key as any]?.folderInfo?.folderPath?.map(
                        (folder: any) => ({
                          label: folder,
                          href: `#`,
                        })
                      )}
                    />
                  </Accordion>
                )}

                {renderProgress(key as string)}
              </div>
            );
          })
        ) : (
          <div className="my-auto w-full">
            <Empty message="No assets uploaded" size="md" />
          </div>
        )}
      </Flex.Row>
      <Flex.Row justifyContent="flex-end" gap={12} className="mt-4">
        <CallToAction.button
          size="sm"
          variant="secondary"
          onClick={async () => {
            await createDisclosure({
              title: 'Discard All',
              message: 'Are you sure you want to discard all images?',
            });
            closeModal();
            setAssets([]);
          }}
        >
          Discard All
        </CallToAction.button>
        <CallToAction.button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleUploadFiles}
          size="sm"
        >
          Upload Files
        </CallToAction.button>
      </Flex.Row>
    </>
  );
};

function exclude<T extends object>(obj: T, key: PropertyKey & keyof T) {
  return Object.keys(obj).reduce((acc, k) => {
    if (k !== key) {
      (acc as Record<string, unknown>)[k] = (obj as Record<string, unknown>)[k];
    }
    return acc;
  }, {} as T);
}
